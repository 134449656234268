<div class="container-fluid">
    <div class="row">
        <div class="col-xl-5 col-12 my-xl-3 mt-2">
            <div class="card shadow rounded-3 p-2 h-100">
                <div class="card-body">
                    <h5>{{ service_name }}</h5>
                    <hr class="h-1" />
                    <p class="text-xs">
                        {{ service_description }}
                    </p>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-12 my-xl-3 my-0">
            <div class="row">
                <div class="col-xl-6 col-12 mt-xl-0 mt-3">
                    <div class="card shadow rounded-3">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center p-3">
                                        <i class="fab fa-cloudsmith fa-2x text-primary"></i>
                                    </div>
                                    <div class="text-end">
                                        <h4>{{ no_of_attempts }}</h4>
                                        <span class="text-xs">No. of Attempts</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12 mt-xl-0 mt-3">
                    <div class="card shadow rounded-3">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center p-3">
                                        <i class="far fa-check-circle fa-2x text-primary"></i>
                                    </div>
                                    <div class="text-end">
                                        <h4>{{ no_successful }}</h4>
                                        <span class="text-xs">No. Successful</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12 mt-3">
                    <div class="card shadow rounded-3">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center p-3">
                                        <i class="fas fa-percentage fa-2x text-primary"></i>
                                    </div>
                                    <div class="text-end">
                                        <h4>{{ Number(success_rate).toFixed(2) }} %</h4>
                                        <span class="text-xs">Success Rate</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12 mt-3">
                    <div class="card shadow rounded-3">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center p-3">
                                        <i class="fas fa-coins fa-2x text-primary"></i>
                                    </div>
                                    <div class="text-end">
                                        <h4>R {{ Number(cost_of_service).toFixed(2) }}</h4>
                                        <span class="text-xs">Cost of Service</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-outline-primary mt-2 rounded-3 shadow mt-xl-0 mt-3">
        <div class="card-body p-0 px-4 py-3">
            <div class="d-flex justify-content-between align-items-center">
                <label class="fw-bold text-center mb-0">
                    <i class="fas fa-play-circle text-success fa-xl pt-2 me-1"></i>
                    Run Service
                </label>
                <button v-if="active_tab !== 'run_service'" @click="set_active_tab('run_service')"
                    class="btn btn-primary-color rounded-pill text-xs">
                    <span> View </span>
                </button>
                <button v-else @click="set_active_tab('')" class="btn btn-primary-color rounded-pill text-xs">
                    <span> Hide </span>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="active_tab === 'run_service'">
                    <hr class="fw-light" />
                    <component :is="service_map[service_name.toLowerCase().replace(/ /g, '_')]" @service_status_complete="service_status_complete" />
                </div>
            </transition>
        </div>
    </div>
    <div class="card card-outline-primary mt-2 rounded-3 shadow">
        <div class="card-body p-0 px-4 py-3">
            <div class="d-flex justify-content-between align-items-center">
                <label class="fw-bold text-center mb-0">
                    <i class="fas fa-folder-tree text-folder fa-xl pt-2 me-1"></i>
                    View Results
                </label>
                <button v-if="active_tab !== 'view_results'" @click="set_active_tab('view_results')"
                    class="btn btn-primary-color rounded-pill text-xs">
                    <span> View </span>
                </button>
                <button v-else @click="set_active_tab('')" class="btn btn-primary-color rounded-pill text-xs">
                    <span> Hide </span>
                </button>
            </div>

            <transition name="slide-fade">
                <div v-if="active_tab === 'view_results'">
                    <hr class="fw-light" />
                    <div class="accordion mb-2" id="results-accordion">
                        <div class="card mb-2 rounded-3" v-for="(result, index) in results" :key="index">
                            <div class="card-header bg-white d-flex justify-content-between align-items-center"
                                :class="{ 'border-none border-bottom rounded-3': !is_accordion_open(index) }"
                                :id="'heading-' + index" @click="toggle_accordion(index)">
                                <h2 class="text-center p-1 text-muted fw-bold text-xs">
                                    Verification Result: {{ format_date(result.date) }}
                                </h2>
                                <i
                                    :class="{ 'fas fa-chevron-down': !is_accordion_open(index), 'fas fa-chevron-up': is_accordion_open(index) }"></i>
                            </div>
                            <div :id="'collapse-' + index" class="collapse"
                                :aria-labelledby="'heading-' + index" data-parent="#results-accordion">
                                <div class="card-body">
                                    <!-- Only the table is rendered here -->
                                    <table class="table table-bordered" :id="'results-table-' + index">
                                        <thead>
                                            <tr>
                                                <th>Label</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, itemIndex) in result.data" :key="itemIndex">
                                                <td>{{ item.label }}</td>
                                                <td>{{ item.value }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    <!-- Updated Service Log section with responsive table, updated search input, and paginator styling -->
    <div class="card card-outline-primary mt-2 rounded-3 shadow">
        <div class="card-body p-0 px-4 py-3">
            <div class="d-flex justify-content-between align-items-center">
                <label class="fw-bold text-center mb-0">
                    <i class="fas fa-book text-secondary fa-xl pt-2 me-1"></i>
                    Service Log
                </label>
                <button v-if="active_tab !== 'service_log'" @click="set_active_tab('service_log')" class="btn btn-primary-color rounded-pill text-xs">
                    <span> View </span>
                </button>
                <button v-else @click="set_active_tab('')" class="btn btn-primary-color rounded-pill text-xs">
                    <span> Hide </span>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="active_tab === 'service_log'">
                    <hr class="fw-light" />
                    <!-- Updated search input styling -->
                    <div class="d-flex justify-content-end mb-2">
                        <input type="text" class="form-control w-50" v-model="search_query" placeholder="Search Log...">
                    </div>
                    <!-- Added responsive table container and updated table classes -->
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover table-sm text-xs">
                            <thead>
                                <tr>
                                    <th class="text-xs">No</th>
                                    <th class="text-xs">Verification Status</th>
                                    <th class="text-xs">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="log in filtered_logs" :key="log.id">
                                    <td class="py-2">{{ log.no }}</td>
                                    <td class="py-2">{{ log.status }}</td>
                                    <td class="py-2">{{ log.date }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr class="h-1" />
                    <!-- Updated paginator styling remains similar -->
                    <div class="d-flex justify-content-between align-items-center">
                        <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_page" :disabled="current_page === 1">
                            <i class="fas fa-arrow-left"></i>
                        </button>
                        <span class="text-xs my-2">Page {{ current_page }} of {{ total_pages }}</span>
                        <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_page" :disabled="current_page === total_pages">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</div>