import BillingCards from './components/billing_cards/BillingCards.vue';
import ClientPerformance from './components/billing_information/ClientPerformance.vue';

export default {
    name: 'BillingComponent',
    components: {
        BillingCards,
        ClientPerformance
    },
    data() {
        return {
            // Add your component data here
        };
    },
    methods: {
        // Add your component methods here
    },
    mounted() {
        // Lifecycle hook when the component is mounted
    }
};