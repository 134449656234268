<script>
import { ref, watch, defineComponent } from "vue";
import Plotly from "plotly.js-dist-min";
import { VuePlotly } from "vue3-plotly";

export default defineComponent({
  name: "pie_chart",
  components: { VuePlotly },
  props: {
    chart_data: {
      type: Array,
      default: () => [],
    },
    chart_layout: {
      type: Object,
      default: () => ({}),
    },
    chart_options: {
      type: Object,
      default: () => ({
        responsive: true,
      }),
    },
    chart_type: {
      type: String,
      default: "pie",
      validator: (value) => ["pie", "sunburst", "donut"].includes(value),
    },
  },
  emits: ["plotly_click", "plotly_hover", "plotly_selected"],
  setup(props, { emit }) {
    const data = ref(props.chart_data);
    const layout = ref(props.chart_layout);
    const options = ref(props.chart_options);
    const type = ref(props.chart_type);

    watch(
      () => props.chart_data,
      (new_val) => {
        data.value = new_val;
      },
      { deep: true }
    );

    watch(
      () => props.chart_layout,
      (new_val) => {
        layout.value = new_val;
      },
      { deep: true }
    );

    watch(
      () => props.chart_options,
      (new_val) => {
        options.value = new_val;
      },
      { deep: true }
    );

    watch(
      () => props.chart_type,
      (new_val) => {
        type.value = new_val;
        if (new_val === "donut") {
          layout.value.hole = 0.4;
        } else {
          delete layout.value.hole;
        }
      }
    );

    const on_plotly_click = (event) => {
      emit("plotly_click", event);
    };

    const on_plotly_hover = (event) => {
      emit("plotly_hover", event);
    };

    const on_plotly_selected = (event) => {
      emit("plotly_selected", event);
    };

    return {
      data,
      layout,
      options,
      type,
      Plotly,
      on_plotly_click,
      on_plotly_hover,
      on_plotly_selected,
    };
  },
});
</script>

<template>
  <div class="chart-container">
    <VuePlotly
      :data="data"
      :layout="layout"
      :options="options"
      :type="type"
      :plotly="Plotly"
      @plotly_click="on_plotly_click"
      @plotly_hover="on_plotly_hover"
      @plotly_selected="on_plotly_selected"
    />
  </div>
</template>