<div>
    <!-- Filter Controls -->
    <div class="card shadow-sm rounded-3 mb-4">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-2">
                <label class="fw-bold text-center mb-0">
                    <i class="fas fa-chart-pie text-primary fa-xl py-2 me-2"></i>
                    Service \ Performance Overview
                </label>
            </div>
            <div class="row g-2">
                <div class="col-md-6">
                    <label for="time-range-select" class="form-label text-xs mb-1">Time Range</label>
                    <select class="form-select form-select-sm" v-model="time_filter"
                        @change="filter_by_time(time_filter)">
                        <option v-for="range in time_ranges" :key="range.value" :value="range.value">
                            {{ range.label }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="service-select" class="form-label text-xs mb-1">Service</label>
                    <select class="form-select form-select-sm" v-model="selected_service"
                        @change="filter_by_service(selected_service)">
                        <option v-for="service in available_services" :key="service.value" :value="service.value">
                            {{ service.label }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6 mb-4">
            <div class="card shadow-sm rounded-3 h-100">
                <div class="card-body">
                    <BarChart :chart_data="filtered_chart_data" :chart_layout="chart_layout" />
                </div>
            </div>
        </div>
        <div class="col-sm-6 mb-4">
            <div class="card shadow-sm rounded-3 h-100">
                <div class="card-body">
                    <DualAxis :labels="dual_axis.labels" :total_data="dual_axis.total_data"
                        :service_data="dual_axis.service_data" :chart_title="dual_axis.chart_title"
                        :xaxis_title="dual_axis.xaxis_title" :yaxis_title="dual_axis.yaxis_title"
                        :y2axis_title="dual_axis.y2axis_title" :bar_color="dual_axis.bar_color"
                        :line_color="dual_axis.line_color" :line_width="dual_axis.line_width" />
                </div>
            </div>
        </div>
        <div class="col-sm-6 mb-4">
            <div class="card shadow-sm rounded-3 h-100">
                <div class="card-body">
                    <BarChart :chart_data="filtered_stacked_chart_data" :chart_layout="stacked_chart_layout" />
                </div>
            </div>
        </div>
        <div class="col-sm-6 mb-4">
            <div class="card shadow-sm rounded-3 h-100">
                <div class="card-body">
                    <LineChart :chart_data="filtered_revenue_trend_data" :chart_layout="revenue_trend_layout" />
                </div>
            </div>
        </div>
        <div class="col-sm-6 mb-4">
            <div class="card shadow-sm rounded-3 h-100">
                <div class="card-body">
                    <PieChart :chart_data="filtered_revenue_by_service_data"
                        :chart_layout="revenue_by_service_layout" />
                </div>
            </div>
        </div>
        <div class="col-sm-6 mb-4">
            <div class="card shadow-sm rounded-3 h-100">
                <div class="card-body">
                    <PieChart :chart_data="filtered_service_usage_data" 
                        :chart_layout="service_usage_layout" />
                </div>
            </div>
        </div>
    </div>
</div>