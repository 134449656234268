<div class="container-fluid mt-4">
  <div class="card rounded-3 border-0 shadow-sm mb-4 p-1">
    <div class="card-body text-center">
      <p class="text-muted text-md">{{ question?.question || 'No Question Found' }}</p>
      <p class="text-secondary text-xs">Please create additional questions below:</p>
    </div>
  </div>

  <div v-for="(option, option_index) in options" :key="option.id" class="mb-3">
    <div class="card shadow rounded-3 border-0">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="mb-1 fw-bold">Option: {{ option.option }}</p>
          </div>
          <div>
            <button
              class="btn bg-green rounded-small text-dark text-xs mx-1"
              @click="option.show_follow_up = !option.show_follow_up"
            >
              <span v-if="!option.show_follow_up">View</span>
              <span v-else>Hide</span>
            </button>
          </div>
        </div>
        <transition name="slide-fade">
          <div v-if="option.show_follow_up">
          <hr class="fw-light" />
          <button
            class="btn bg-green rounded-small text-dark text-xs"
            @click="add_question(option.id)"
          >
            Add Follow Up Question
          </button>
            <!-- Questions -->
            <div
              v-if="questions[option.id]"
              v-for="(question, index) in questions[option.id]"
              :key="index"
              class="card rounded-3 p-2 mt-3"
            >
              <div class="card-body">
                <label for="">Create new question:</label>
                <form>
                  <div class="row mt-2 mb-3">
                    <div class="col">
                      <label class="form-label fw-bold text-xs"
                        ><small>Question</small></label
                      >
                      <input
                        v-model="question.question"
                        type="text"
                        class="form-control text-xs rounded-small p-3 shadow-sm"
                        required
                        placeholder="e.g Are you a director of a company?"
                      />
                    </div>
                    <div class="col">
                      <label class="form-label fw-bold text-xs"
                        ><small>Question Number</small></label
                      >
                      <input
                        v-model="question.question_number"
                        type="text"
                        class="form-control text-xs rounded-small p-3 shadow-sm"
                        placeholder="e.g. 1"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <label class="form-label fw-bold text-xs"
                        ><small>Question Type</small></label
                      >
                      <select
                        v-model="question.question_type"
                        class="form-select text-xs rounded-small p-2 shadow-sm"
                      >
                        <option value="" disabled hidden>Select Type</option>
                        <option value="File">File</option>
                        <option value="Date">Date</option>
                        <option value="Text">Text</option>
                        <option value="Checkbox">Checkbox</option>
                        <option value="Selection">Selection</option>
                        <option value="Number">Number</option>
                      </select>
                    </div>
                    <div class="col">
                      <label class="form-label fw-bold text-xs"
                        ><small>Is question mandatory?</small></label
                      >
                      <select
                        v-model="question.mandatory"
                        class="form-select text-xs rounded-small p-2 shadow-sm"
                      >
                        <option value="" disabled hidden>Select Option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <h6 v-if="['Checkbox', 'Selection', 'Multi Selection'].includes(question.question_type)">
                    <strong>Add Options If Applicable</strong>
                  </h6>
                  <div class="row">
                    <div v-if="['Checkbox', 'Selection', 'Multi Selection'].includes(question.question_type)" class="col-12 mb-3">
                      <div class="mb-3">
                        <label for="option_1" class="form-label fw-bold text-xs"
                          ><small
                            ><span
                              >Enter number of options for your question</span
                            ></small
                          ></label
                        >
                        <input
                          class="form-control text-xs rounded-small p-3 shadow-sm"
                          type="number"
                          id="number_of_options"
                          min="1"
                          v-model="question.number_of_options"
                          @input="validate_number_of_options(option.id, index)"
                          placeholder="Enter number of options"
                        />
                      </div>
                      
                      <!-- Options container with scrolling -->
                      <div class="options-container mt-3 options-scroll">
                        <div v-for="(opt, idx) in question.options" :key="idx" class="mb-2">
                          <input
                            class="form-control text-xs rounded-small p-3 shadow-sm options"
                            type="text"
                            v-model="question.options[idx]"
                            :placeholder="`Option ${idx + 1}`"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="['Checkbox', 'Selection', 'Multi Selection'].includes(question.question_type)" class="col hide_field">
                      <label
                        for="other_field"
                        class="form-label fw-bold text-xs"
                        ><small
                          >Do you have an extra field for
                          <span>other</span> options? *</small
                        ></label
                      >
                      <select
                        class="form-select text-xs rounded-small p-2 shadow-sm"
                        aria-label="other_field"
                        name="other_field"
                        id="other_field"
                      >
                        <option hidden selected value="">Select Option</option>
                        <option value="False">No</option>
                        <option value="True">Yes</option>
                      </select>
                    </div>
                    <div class="col"></div>
                  </div>
                </form>
                <div class="d-flex justify-content-end align-items-center mt-3">
                  <button
                    class="btn bg-green btn-sm text-xxs fw-bold rounded-small shadow-sm me-2"
                    @click="save_question(question, option.id, index)"
                  >
                    Create Question
                  </button>
                  <button
                    class="btn btn-sm bg-green rounded-circle text-xs fw-bold shadow-sm"
                    title="Remove button"
                    @click="remove_question(option.id, index)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="option.follow_up_question && option.follow_up_question.length > 0"
            >
              <div
                v-for="(follow_up, index) in option.follow_up_question"
                :key="follow_up.id"
                class="mt-3"
              >
                <div class="card rounded-3 p-2">
                  <div class="card-body">
                    <form>
                      <div class="row mt-2 mb-3">
                        <div class="col">
                          <label class="form-label fw-bold text-xs"
                            ><small>Question</small></label
                          >
                          <input
                            v-model="follow_up.question"
                            type="text"
                            class="form-control text-xs rounded-small p-3 shadow-sm"
                            required
                            placeholder="e.g What is your company registration number?"
                          />
                        </div>
                        <div class="col">
                          <label class="form-label fw-bold text-xs"
                            ><small>Question Number</small></label
                          >
                          <input
                            v-model="follow_up.number"
                            type="text"
                            class="form-control text-xs rounded-small p-3 shadow-sm"
                            placeholder="e.g. 1"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col">
                          <label class="form-label fw-bold text-xs"
                            ><small>Question Type</small></label
                          >
                          <select
                            v-model="follow_up.question_type"
                            class="form-select text-xs rounded-small p-2 shadow-sm"
                          >
                            <option value="" disabled hidden>
                              Select Type
                            </option>
                            <option value="File">File</option>
                            <option value="Date">Date</option>
                            <option value="Text">Text</option>
                            <option value="Checkbox">Checkbox</option>
                            <option value="Selection">Selection</option>
                            <option value="Number">Number</option>
                          </select>
                        </div>
                        <div class="col">
                          <label class="form-label fw-bold text-xs"
                            ><small>Is question mandatory?</small></label
                          >
                          <select
                            v-model="follow_up.is_required"
                            class="form-select text-xs rounded-small p-2 shadow-sm"
                          >
                            <option value="" disabled hidden>
                              Select Option
                            </option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                          </select>
                        </div>
                      </div>
                      
                      <!-- Number of options input first -->
                      <div
                        class="row mb-3"
                        v-if="follow_up.question_type === 'Selection' || follow_up.question_type === 'Checkbox'"
                      >
                        <div class="col-12">
                          <label class="form-label fw-bold text-xs"
                            ><small>Number of Options</small></label
                          >
                          <input
                            v-model="follow_up.number_of_options"
                            type="number"
                            class="form-control text-xs rounded-small p-3 shadow-sm"
                            min="1"
                            max="150"
                            placeholder="Enter number of options"
                            @input="edit_validate_number_of_options(option_index, index)"
                          />
                        </div>
                      </div>
                      
                      <!-- Scrollable options container underneath -->
                      <div
                        v-if="follow_up.question_type === 'Selection' || follow_up.question_type === 'Checkbox'"
                        class="row mb-3"
                      >
                        <div class="col-12">
                          <div class="options-container options-scroll">
                            <div
                              v-for="(option, idx) in follow_up.options"
                              :key="idx"
                              class="mb-2"
                            >
                              <input
                                v-model="follow_up.options[idx]"
                                type="text"
                                class="form-control text-xs rounded-small p-3 shadow-sm"
                                :placeholder="`Option ${idx + 1}`"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div
                      class="d-flex justify-content-end align-items-center mt-3"
                    >
                      <button
                        class="btn btn-dark rounded-small fw-bold btn-sm me-2 text-xxs text-white"
                        @click="edit_follow_up_question(follow_up, option.id)"
                      >
                        Update
                      </button>
                      <button
                        v-if="follow_up.is_active == true"
                        class="btn btn-danger rounded-small fw-bold btn-sm me-2 text-xxs text-white"
                        @click="deactivate_follow_up_question(follow_up.id)"
                      >
                        Deactivate
                      </button>
                      <button
                        v-else
                        class="btn bg-success text-white rounded-small fw-bold btn-sm me-2 text-xxs"
                        @click="activate_follow_up_question(follow_up.id)"
                      >
                        Activate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</div>
