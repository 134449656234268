import { LOGOUT } from "@/utils/logout.js";

export default {
    mixins: [LOGOUT],
    data: () => ({
      user_info: {},
      show_project_tabs: false,
      show_project_dashboard_tabs: false,
      show_instant_verify_tabs: false,
      sidebarIcons: {
        gold: require('@/assets/side_bar_icons/gold.svg'),
        layout: require('@/assets/side_bar_icons/layout.svg'),
        list: require('@/assets/side_bar_icons/list.svg'),
        report: require('@/assets/side_bar_icons/report.svg'),
        users: require('@/assets/side_bar_icons/users.svg'),
        verified: require('@/assets/side_bar_icons/verified.svg'),
        logout: require('@/assets/side_bar_icons/logout.svg'),
        dashboard: require('@/assets/side_bar_icons/dashboard.svg'),
        services: require('@/assets/side_bar_icons/services.svg'),
        applications : require('@/assets/side_bar_icons/code.svg'),
        billing: require('@/assets/side_bar_icons/billing.svg')
      }
    }),

    computed: {
      // Add computed properties for icon mapping
      iconMapping() {
        return {
          Dashboard: this.sidebarIcons.dashboard,
          Services: this.sidebarIcons.services,
          Questions: this.sidebarIcons.list,
          Forms: this.sidebarIcons.report,
          Templates: this.sidebarIcons.layout,
          Assets: this.sidebarIcons.gold,
          Users: this.sidebarIcons.users,
          InstantVerify: this.sidebarIcons.verified,
          Applications: this.sidebarIcons.applications
        }
      }
    },

    watch: {
      $route() {
        this.update_project_tabs();
        this.update_project_dashboard_tabs();
        this.update_instant_verify_tabs();
      }
    },

    methods: {
      route_to() {
        this.logout();
      },
      
      get_user_information() {
        const TOKEN = localStorage.getItem('token');
        const GET_USER_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_user_api/`;
  
        $.ajax({
          url: GET_USER_INFORMATION_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          success: (response) => {
            Swal.close();
            this.user_info = response.data;
          },
          error: () => {
            Swal.close();
          }
        });
      },

      update_project_tabs() {
        const project_routes = [
          'service_dashboard', 'edit_workflow','workflow_router', 'view_workflow', 'workflow_services',
          'service_rules','client_services', 'arrange_workflow', 'reasons_for_usage', 'upload_consent',
          'confirm_workflow', 'workflow_dashboard', 'view_customers', 'view_service',
          'create_asset', 'view_asset', 'project_dashboard', 'project_table',
          'question_management', 'conditional_questions','form_management', 'template_management', 'manage_form',
          'client_admin', 'customer_data', 'template_configuration',
          'view_client', 'view_client_admin', 'customer_applications'
        ];
        this.show_project_tabs = project_routes.includes(this.$route.name);
      },
      
      update_project_dashboard_tabs() {
        const project_dashboard_routes = [
          'project_dashboard', 'service_dashboard', 'edit_workflow', 'workflow_router', 'view_workflow',
          'workflow_services','service_rules', 'client_services', 'arrange_workflow', 'reasons_for_usage',
          'upload_consent', 'confirm_workflow', 'workflow_dashboard', 'view_customers',
          'view_service', 'create_asset', 'view_asset','template_management', 'question_management', 
          'conditional_questions','form_management','client_admin', 'customer_data', 
          'template_configuration',
          'view_client', 'view_client_admin'
        ];
        this.show_project_dashboard_tabs = project_dashboard_routes.includes(this.$route.name);
      },

      update_instant_verify_tabs() {
        const instant_verify_routes = ['instant_verify', 'instant_services', 'services', 'verify_logs', 'utilize_service'];
        this.show_instant_verify_tabs = instant_verify_routes.includes(this.$route.name);
      },

      getIcon(iconName) {
        return this.iconMapping[iconName] || this.sidebarIcons.list; // default to list icon if not found
      }
    },

    mounted() {
      this.get_user_information();
      this.update_project_tabs();
      this.update_project_dashboard_tabs();
      this.update_instant_verify_tabs();
    }
};