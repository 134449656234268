
<div class="form-container">
    <form @submit.prevent="handle_register" class="mobile-friendly-form">
        <div class="text-center mb-5">
            <h2 class="text-dark fw-bold text-md">Create Your Account</h2>
            <p class="text-dark text-xs">Please fill out the form below to set up a new account.</p>
        </div>

        <!-- Name Fields -->
        <div class="form-group">
            <div class="form-floating">
                <input
                    type="text"
                    class="form-control-nb bottom-border-input text-xs"
                    placeholder=" "
                    v-model="first_name"
                    name="first_name"
                    id="first-name-input"
                    @input="validate_name('first_name')"
                />
                <label for="first-name-input" class="fw-bold text-xs">First Name</label>
            </div>
            <small class="text-danger p-2 text-xxs fw-bold d-none first-name-message"></small>
        </div>

        <div class="form-group">
            <div class="form-floating">
                <input
                    type="text"
                    class="form-control-nb bottom-border-input text-xs"
                    placeholder=" "
                    v-model="last_name"
                    name="last_name"
                    id="last-name-input"
                    @input="validate_name('last_name')"
                />
                <label for="last-name-input" class="fw-bold text-xs">Last Name</label>
            </div>
            <small class="text-danger p-2 text-xxs fw-bold d-none last-name-message"></small>
        </div>

        <!-- Contact Fields -->
        <div class="form-group">
            <div class="form-floating">
                <input
                    type="email"
                    class="form-control-nb bottom-border-input text-xs"
                    placeholder=" "
                    v-model="email"
                    name="email"
                    id="email-input"
                    @input="convert_email_to_lowercase"
                />
                <label for="email-input" class="fw-bold text-xs">Email</label>
            </div>
            <small class="text-danger p-2 text-xxs fw-bold d-none email-message"></small>
            <small class="text-danger p-2 text-xxs fw-bold d-none email-exists-message"></small>
            <small class="text-danger p-2 text-xxs fw-bold d-none email-error-message"></small>
        </div>

        <!-- Phone Number Field -->
        <div class="form-group">
            <div class="form-floating phone-input-container">
                <input
                    type="tel"
                    class="form-control-nb bottom-border-input text-xs"
                    placeholder="0XX XXX XXXX"
                    v-model="phone_number"
                    name="phone_number"
                    id="phone-input"
                    maxlength="10"
                />
            </div>
            <small class="text-danger p-2 text-xxs fw-bold" v-if="phone_number_error">
                {{ phone_error_message }}
            </small>
            <small class="text-danger p-2 text-xxs fw-bold d-none phone-number-message"></small>
            <small class="text-danger p-2 text-xxs fw-bold d-none mobile-exists-message"></small>
        </div>

        <!-- Password Fields -->
        <div class="form-group">
            <div class="form-floating position-relative">
                <input
                class="form-control-nb bottom-border-input text-xs"
                placeholder=" "
                v-model="password"
                name="password"
                :type="show_password ? 'text' : 'password'"
                id="password-input"
                @input="check_password_strength"
                @focus="password_field_active = true"
                @blur="password_field_active = false"
            />
                <label for="password-input" class="fw-bold text-xs">Password</label>
                <span class="eye-icon" @click="toggle_password_view">
                    <i :class="show_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                </span>
            </div>
            <small class="text-danger p-2 text-xxs fw-bold d-none password-requirements-message"></small>
            <small class="text-danger p-2 text-xxs fw-bold d-none password-message"></small>

            <!-- Password Requirements -->
            <transition name="fade-slide">
                <div class="password-requirements" :class="{ 'active': password_field_active }">
                    <div class="password-strength-container">
                        <div class="strength-label d-flex justify-content-between align-items-center">
                            <span class="text-muted">Password Strength:</span>
                            <span :class="strength_text_class">{{ password_strength }}</span>
                        </div>
                        <div class="strength-bar">
                            <div :class="['strength-level', strength_bar_class]"></div>
                        </div>
                    </div>

                    <div class="requirements-list">
                        <div 
                            v-for="(met, requirement) in requirements" 
                            :key="requirement"
                            class="requirement-item"
                            :class="{ 'requirement-met': met }"
                        >
                            <i :class="met ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
                            <span>{{ 
                                requirement === 'lowercase' ? 'At least one lowercase' :
                                requirement === 'uppercase' ? 'At least one uppercase' :
                                requirement === 'numeric' ? 'At least one number' :
                                requirement === 'special' ? 'At least one special character' :
                                'Minimum 8 characters'
                            }}</span>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <!-- Confirm Password -->
        <div class="form-group">
            <div class="form-floating position-relative">
                <input
                    class="form-control-nb bottom-border-input text-xs"
                    :class="{
                        'is-invalid': !passwords_match && confirm_password.length > 0,
                        'is-valid': passwords_match && confirm_password.length > 0
                    }"
                    placeholder=" "
                    v-model="confirm_password"
                    name="confirm_password"
                    :type="show_confirm_password ? 'text' : 'password'"
                    id="confirm-password-input"
                    @input="password_match"
                />
                <label for="confirm-password-input" class="fw-bold text-xs">Confirm Password</label>
                <span class="eye-icon" @click="toggle_confirm_password_view">
                    <i :class="show_confirm_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                </span>
            </div>
            <small class="text-danger p-2 text-xxs fw-bold d-none confirm-pswd-message"></small>
            <small class="text-danger p-2 text-xxs fw-bold d-none match-password-message"></small>
        </div>

        <!-- Dynamic Questions Component -->
        <GetQuestions
            :client_admin_id="client_admin_id"
            ref="get_questions"
            page_type="register"
            @update-responses="store_responses"
        />

        <!-- Terms Modal and Checkbox -->
        <TermsModal 
        v-if="client === 'suzuki' && client_admin_id && client_admin_id !== 'null'"
        ref="termsModal"
        :client="client"
        @accept="handleTermsAccept"
        @decline="handleTermsDecline"
        />

        <div class="checkbox-wrapper" v-if="client === 'suzuki' && client_admin_id && client_admin_id !== 'null'">
        <input 
            type="checkbox" 
            class="checkbox-input" 
            v-model="acceptTerms" 
            id="termsCheck"
            @click="openTermsModal"
            required
        >
        <label class="checkbox-label fw-bold text-xs" for="termsCheck">
            I Accept {{ clientName }}'s <a href="#" @click.prevent="openTermsModal" class="text-primary">Indemnity Notice</a>
        </label>
        </div>

        <!-- Marketing Modal and Checkbox -->
        <MarketingConsentModal 
        v-if="client === 'suzuki' && client_admin_id && client_admin_id !== 'null'"
        ref="marketingConsentModal"
        :client="client"
        @accept="handleMarketingAccept"
        @decline="handleMarketingDecline"
        />

        <div class="checkbox-wrapper" v-if="client === 'suzuki' && client_admin_id && client_admin_id !== 'null'">
        <input 
            type="checkbox" 
            class="checkbox-input" 
            v-model="acceptMarketing" 
            id="marketingCheck"
            @click="openMarketingModal"
        >
        <label class="checkbox-label fw-bold text-xs" for="marketingCheck">
            I Consent to 
            <a href="#" @click.prevent="openMarketingModal" class="text-primary">Marketing Communications</a>
        </label>
        </div>

        <div class="form-group">
            <small class="text-danger p-2 text-xxs fw-bold d-none error-message"></small>
        </div>

        <!-- Submit Button -->
        <div class="mb-3">
            <button
                type="submit"
                class="btn btn-bg-green secondary-text-dark text-xs w-75 fw-bold mt-2"
            >
                Create Account
            </button>
        </div>

        <!-- Login Link -->
        <div class="mt-1 text-center">
            <span class="text-dark text-xs">Already have an account?</span>
            <router-link to="/" class="text-primary text-xs"> Login here</router-link>
        </div>
    </form>
</div>