import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";
import AddressForm from './components/address_form/AddressForm.vue';
import AmlForm from './components/aml_form/AmlForm.vue';
import RefugeeForm from './components/refugee_form/RefugeeForm.vue';
import QualificationForm from './components/qualification_form/QualificationForm.vue';
import CompanyForm from './components/company_form/CompanyForm.vue';
import AFISForm from './components/afis_form/AFISForm.vue';
import IDForm from './components/id_form/IDForm.vue';

export default {
    name: 'ServiceUtilize',
    mixins: [LOGOUT],
    components: {
        AddressForm,
        AmlForm,
        RefugeeForm,
        QualificationForm,
        CompanyForm,
        AFISForm,
        IDForm
    },
    data() {
        return {
            active_tab: 'run_service',
            results: [],
            logs: [],
            search_query: '',
            current_page: 1,
            items_per_page: 10,
            service_name: '',
            service_description: '',
            no_of_attempts: 0,
            no_successful: 0,
            success_rate: 0,
            cost_of_service: 0,
            service_map: {
                anti_money_laundering: AmlForm,
                refugee_verification: RefugeeForm,
                company_verification_pdf : CompanyForm,
                qualification_check : QualificationForm,
                afis_criminal_check : AFISForm,
                address_match : AddressForm,
                rsa_id_verification: IDForm,
            }
        };
    },
    computed: {
        filtered_logs() {
            let filtered = this.logs.filter(log => {
                return log.status.toLowerCase().includes(this.search_query.toLowerCase()) ||
                    log.date.includes(this.search_query) || log.no.toString().includes(this.search_query);
            });
            return filtered.slice((this.current_page - 1) * this.items_per_page, this.current_page * this.items_per_page);
        },
        total_pages() {
            return Math.ceil(this.logs.length / this.items_per_page);
        }
    },
    methods: {

        format_date(date){
            return new Date(date).toLocaleString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).replace(',', '');
        },
        service_status_complete() {
            this.get_service_information();
        },
        get_result(results) {
            let history = [];
            results.forEach(element => {
                let response = element.response;
                response = this.preprocess_data(response);
                history.push({
                    date: element.date_created,
                    data: response
                });
            });
            return history;
        },
        preprocess_data(data, parent_key = '') {
            let result = [];

            let format_key = (key, parent) => (parent ? `${parent}.${key}` : key);

            Object.entries(data).forEach(([key, value]) => {
                let current_key = format_key(key, parent_key);

                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        if (typeof item === 'object' && item !== null) {
                            result.push(...this.preprocess_data(item, `${current_key}[${index}]`));
                        } else{  const processed_value = (item === null || item === undefined || item === '') ? 'N/A' : item; 
                            result.push({
                                type: 'input',
                                key: `${current_key}[${index}]`,
                                label: `${key} [${index}]`,
                                value: processed_value,
                            });
                        }
                    });
                } else if (typeof value === 'object' && value !== null) {
                    result.push({
                        type: 'heading',
                        key: current_key,
                        label: key,
                    });
                    result.push(...this.preprocess_data(value, current_key));
                } else if (value !== null && value !== '') {
                    result.push({
                        type: 'input',
                        key: current_key,
                        label: key,
                        value: value,
                    });
                }
            });
            return result;
        },
        format_label(key) {
            if (typeof key !== 'string' || !key.trim()) return '';

            return key
                .replace(/[_-]/g, ' ')
                .replace(/[^a-zA-Z0-9]/g, ' ') // Replace non-alphanumeric characters with a space
                .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters following a lowercase letter
                .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space between consecutive uppercase letters followed by a lowercase
                .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .trim() // Trim leading/trailing spaces
                .split(' ') // Split the string into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
                .join(' '); // Join the words back into a single string
        },
        set_active_tab(tab) {
            this.active_tab = tab;
        },
        toggle_accordion(id) {
            var element = $('#collapse-' + id);
            if (element.length) {
                var is_collapsed = element.hasClass('show');
                if (is_collapsed) {
                    element.removeClass('show');
                } else {
                    element.addClass('show');
                    // Reinitialize DataTable when accordion opens
                    this.$nextTick(() => {
                        const table = element.find('table')[0];
                        if (table && !$.fn.DataTable.isDataTable(table)) {
                            $(table).DataTable({
                                responsive: true,
                                dom: 'Bfrtip',
                                buttons: [
                                    'copy', 'csv', 'excel', 'pdf'
                                ],
                                order: [[2, 'desc']]
                                
                            });
                        }
                    });
                }
            }
        },
        is_accordion_open(id) {
            var element = $('#collapse-' + id);
            return element.hasClass('show');
        },
        previous_page() {
            if (this.current_page > 1) {
                this.current_page--;
            }
        },
        next_page() {
            if (this.current_page < this.total_pages) {
                this.current_page++;
            }
        },
        get_log(results) {
            var logs = [];
            this.logs = results.forEach((element, indexOf) => {
                logs.push({
                    no: indexOf + 1,
                    status: element.api_result_status,
                    date: element.date_created
                });
            });
            logs.sort((a, b) => new Date(b.date) - new Date(a.date));
            return logs;
        },
        get_service_information() {
            const TOKEN = localStorage.getItem('token');
            const service_id = this.$route.params.service_id;
            const GET_SERVICE_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/user_service_details_api/`;

            $.ajax({
                url: GET_SERVICE_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: { service_id: service_id },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    const data = response.data;
                    this.service_name = data.service.service_name;
                    this.service_description = data.service_details.service_description;
                    this.no_of_attempts = data.number_of_requests;
                    this.no_successful = data.number_success;
                    this.success_rate = data.number_of_requests !== 0 
    ? ((data.number_success / data.number_of_requests) * 100).toFixed(2) 
    : "0.00";
                    this.cost_of_service = data.cost.total_cost;
                    this.results = data.service_history ? this.get_result(data.service_history) : [];
                    this.logs = data.service_log ? this.get_log(data.service_log) : [];
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Failed to get service information';
                    if (error.responseJSON) {
                        const ERROR_RESPONSE = error.responseJSON;
                        if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                            error_message = ERROR_RESPONSE.error;
                        }
                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }

        this.get_service_information();
    }
};