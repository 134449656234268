<div>
  <div v-for="(field, index) in dynamic_fields" :key="index" class="form-group mb-2">
    <label :for="field.question" class="fw-bold text-xs text-start d-block mb-2">
      {{ field.question }} <span v-if="field.is_required" class="text-danger">*</span>
    </label>
    <!-- Text Input -->
    <div class="form-group mb-2">
      <input v-if="field.has_text" type="text" class="form-control-nb bottom-border-input text-xs w-100"
        v-model="field.value" :placeholder="field.question" :name="field.question" />
    </div>

    <!-- Number Input -->
    <div class="form-group mb-2">
      <input v-if="field.has_number" type="number" class="form-control-nb bottom-border-input text-xs w-100"
        v-model="field.value" :placeholder="field.question" :name="field.question" />
    </div>

    <!-- Checkbox Options -->
    <div v-if="field.has_checkbox" class="form-group mb-2 me-5 text-start">
      <div v-for="option in field.options" :key="option" class="d-flex align-items-center mb-0">
        <input type="radio" class="form-check-input me-2" v-model="field.value" :value="option.option"
          :id="`checkbox_${index}_${option.option}`" @change="get_follow_up_question(field.question_id, option.id)" />
        <label :for="`checkbox_${index}_${option.option}`" class="form-check-label text-xs">{{ option.option }}</label>
      </div>
    </div>

    <!-- Dropdown for Selections -->
    <div v-if="field.has_selection" class="form-group mb-2 text-center">
      <select class="form-control-nb bottom-border-input text-xs w-100" v-model="field.value" :id="`select_${index}`"
        @change="get_follow_up_question(field.question_id, $event.target.value)">
        <option value="" disabled hidden>{{ field.question }}</option>
        <option v-for="option in field.options" :key="option.id" :value="option.option">{{ option.option }}</option>
      </select>
    </div>

    <!-- Multi-selection Checkboxes -->
    <div v-if="field.has_multi_selection">
      <div v-for="option in field.options" :key="option.option" class="form-check">
        <input type="checkbox" class="form-check-input rounded-small shadow-sm" v-model="responses[index].value"
          :value="option.option" :id="`checkbox_${index}_${option.option}`" />
        <label :for="`checkbox_${index}_${option.option}`" class="text-xs text-start d-block">
          {{ option.option }}
        </label>
      </div>
    </div>

    <!-- File Input -->
    <div v-show="field.has_file" class="form-group mb-2 text-start">
      <input
      type="file"
      accept=".pdf"
      @change="($event) => on_file_change($event, field.question_id, false)"
      ref="file_input"
      :name="`file_${field.question_id}`"
      class="form-control form-control-sm rounded text-xs w-100" />
    </div>

    <!-- Date Input -->
    <div v-if="field.has_date" class="form-group mb-2 text-start">
      <input type="date" class="form-control form-control-sm rounded text-xs w-100" v-model="field.value"
        :placeholder="field.question" :name="field.question" />
    </div>

    <small :id="`error-${field.question_id}`" class=" text-danger fw-bold text-xxs d-none">
      Please fill in this field
    </small>

    <!-- Follow-up Questions Section -->
    <div v-if="follow_up_questions.length > 0" class="mt-4">
      <div v-for="follow_up in follow_up_questions" :key="follow_up.question_id">
        <div v-show="follow_up.parent_id === field.question_id" class="mb-4">
          <label class="text-xs text-start fw-bold d-block mb-2">
            {{ follow_up.question_text }}
            <span v-if="follow_up.is_required" class="text-danger">*</span>
          </label>

          <div class="form-group mt-2">
            <!-- Text Input -->
            <div v-if="follow_up.has_text" class="form-group mb-2 text-start">
              <input :type="text"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xs rounded-small p-2 shadow-sm"
                v-model="follow_up.value" :placeholder="follow_up.question_text" :required="follow_up.is_required"
                @input="update_follow_up_response(follow_up.question_id, follow_up.value)" 
                :name="`follow_up_text_${follow_up.question_id}`"/>
              <small :id="`error-followup-${follow_up.question_id}`" class=" text-danger fw-bold text-xxs d-none">
                Please fill in this field
              </small>
            </div>

            <!-- Number Input -->
            <div v-if="follow_up.has_number" class="form-group mb-2 text-start">
              <input :type="number"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xs rounded-small p-2 shadow-sm"
                v-model="follow_up.value" :placeholder="follow_up.question_text" :required="follow_up.is_required"
                @input="update_follow_up_response(follow_up.question_id, follow_up.value)"
                :name="`follow_up_file_${follow_up.question_id}`" />
              <small :id="`error-followup-${follow_up.question_id}`" class=" text-danger fw-bold text-xxs d-none">
                Please fill in this field
              </small>
            </div>

            <!-- Multi-selection Checkboxes -->
            <div v-if="follow_up.has_multi_selection" class="form-group mb-2 text-start">
              <div v-for="option in follow_up.options" :key="`${follow_up.question_id}_${option.option}`"
                class="form-check">
                <input type="checkbox"
                  class="form-check-input mx-auto col-12 col-md-9 col-lg-8 text-xs rounded-small p-2 shadow-sm"
                  v-model="follow_up.value" :value="option"
                  :id="`follow_up_checkbox_${follow_up.question_id}_${option.option}`"
                  @input="update_follow_up_response(follow_up.question_id, follow_up.value)" />
                <label :for="`follow_up_checkbox_${follow_up.question_id}_${option.option}`"
                  class="form-check-label text-xs ms-1">
                  {{ option.option }}
                </label>
              </div>
              <small :id="`error-followup-${follow_up.question_id}`" class=" text-danger fw-bold text-xxs d-none">
                Please fill in this field
              </small>
            </div>

            <!-- Single-selection Radio Buttons -->
            <div v-if="follow_up.has_checkbox" class="form-check text-start">
              <div v-for="option in follow_up.options" :key="`${follow_up.question_id}_${option.option}`">
                <input type="radio" class="form-check-input text-xs rounded-small p-2 shadow-sm"
                  :name="`follow_up_radio_${follow_up.question_id}`" v-model="follow_up.value" :value="option.option"
                  @input="update_follow_up_response(follow_up.question_id, option.option)"
                  :id="`follow_up_radio_${follow_up.question_id}_${option.option}`" :required="follow_up.is_required" />
                <label :for="`follow_up_radio_${follow_up.question_id}_${option.option}`"
                  class="form-check-label text-xs ms-1">
                  {{ option.option }}
                  <span v-if="follow_up.is_required" class="text-danger">*</span>
                </label>
              </div>
              <small :id="`error-followup-${follow_up.question_id}`" class=" text-danger fw-bold text-xxs d-none">
                Please fill in this field
              </small>
            </div>

            <!-- Dropdown Selection -->
            <div v-if="follow_up.has_selection" class="form-group mb-2 text-start">
              <select
                class="form-select form-select-sm mx-auto col-12 col-md-9 col-lg-8 text-xs rounded-small p-2 shadow-sm"
                v-model="follow_up.value" @input="update_follow_up_response(follow_up.question_id, $event.target.value)"
                :required="follow_up.is_required">
                <option value="" disabled hidden>{{ follow_up.question_text }}
                  <span v-if="follow_up.is_required" class="text-danger">*</span>
                </option>
                <option v-for="option in follow_up.options" :key="`${follow_up.question_id}_${option.option}`"
                  :value="option.option">
                  {{ option.option }}
                </option>
              </select>
              <small :id="`error-followup-${follow_up.question_id}`" class=" text-danger fw-bold text-xxs d-none">
                Please fill in this field
              </small>
            </div>

            <!-- File Upload -->
            <div v-show="follow_up.has_file" class="form-group mb-2 text-start">
              <input
                type="file"
                accept=".pdf"
                @change="($event) => on_file_change($event, follow_up.question_id, true)"
                :ref="`followup_file_input_${follow_up.question_id}`"
                :name="`followup_file_${follow_up.question_id}`"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xs rounded-small p-2 shadow-sm" />
              <small :id="`error-followup-${follow_up.question_id}`" class=" text-danger fw-bold text-xxs d-none">
                Please fill in this field
              </small>
            </div>

            <!-- Date Input -->
            <div v-if="follow_up.has_date" class="form-group mb-2 text-start">
              <input type="date"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xs rounded-small p-2 shadow-sm"
                v-model="follow_up.value" :required="follow_up.is_required"
                @input="update_follow_up_response(follow_up.question_id, follow_up.value)" />
              <small :id="`error-followup-${follow_up.question_id}`" class=" text-danger fw-bold text-xxs d-none">
                Please fill in this field
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>