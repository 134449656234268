import GaugeChart from '@/components/Graphs/GaugeChart.vue';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";

export default {
    name: 'billing_cards',
    components: {
        GaugeChart
    },
    mixins: [LOGOUT],
    mounted(){
        if (!validateToken(this.$router)) {
            return;
        }

        this.device_screen = window.innerWidth;
        this.$nextTick(() => {
            if($('#gauge-chart').width()){
                this.card_screen.width = parseInt(parseFloat($('#gauge-chart').width()) * 0.50);
            }
        })
        window.addEventListener('resize', () => {
            this.device_screen = window.innerWidth;
        });
        this.get_card_information();
    },
    data() {
        return {
            card_screen: {
                width: null
            },
            device_screen: 0,
            active_users: [],
            total_users: 0,
            percentage: null,
            cards: []
        };
    },
    methods: {
        assign_suffix_to_amount(amount){
            if (amount >= 1000000000) {
                return `${Number(amount / 1000000000).toFixed(2)}B`;
            } else if (amount >= 1000000) {
                return `${Number(amount / 1000000).toFixed(2)}M`;
            } else if (amount >= 1000) {
                return `${Number(amount / 1000).toFixed(2)}k`;
            } else {
                return Number(amount).toFixed(2);
            }
        },
        get_card_information(){
            const TOKEN = localStorage.getItem('token');
            const GET_SERVICE_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/get_all_billing_info/`;

            $.ajax({
                url: GET_SERVICE_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    this.active_users = response.data.active_clients || 0;
                    this.total_users = response.data.total_clients || 0;

                    this.percentage = this.total_users == 0 ? 0 : Number(this.active_users * 100 / this.total_users).toFixed(2);
                    
                    var revenue_this_cycle = response.data.revenue_this_cycle || 0.00;
                    var revenue_last_cycle = response.data.revenue_last_cycle || 0.00;
                    var revenue_last_year = response.data.revenue_last_year || 0.00;
                    var revenue_this_year = response.data.revenue_this_year || 0.00;
                    var cost_of_services_this_cycle = response.data.cost_of_services_this_cycle || 0.00;
                    var cost_of_services_last_cycle = response.data.cost_of_services_last_cycle || 0.00;

                    this.cards = [
                        {
                            title: 'Total Revenue This Cycle',
                            value: `R ${this.assign_suffix_to_amount(revenue_this_cycle)}`,
                            icon: 'fas fa-chart-line',
                        },
                        {
                            title: 'Total Revenue Last Cycle',
                            value: `R ${this.assign_suffix_to_amount(revenue_last_cycle)}`,
                            icon: 'fas fa-chart-bar',
                        },
                        {
                            title: 'Cost of Services This Cycle',
                            value: `R ${this.assign_suffix_to_amount(cost_of_services_this_cycle)}`,
                            icon: 'fas fa-money-bill-wave',
                        },
                        {
                            title: 'Cost of Services Last Cycle',
                            value: `R ${this.assign_suffix_to_amount(cost_of_services_last_cycle)}`,
                            icon: 'fas fa-money-check-alt',
                        },
                        {
                            title: 'Total Revenue This Year',
                            value: `R ${this.assign_suffix_to_amount(revenue_this_year)}`,
                            icon: 'fas fa-dollar-sign',
                        },
                        {
                            title: 'Total Revenue Last Year',
                            value: `R ${this.assign_suffix_to_amount(revenue_last_year)}`,
                            icon: 'fas fa-coins',
                        }
                    ]
                },
                error: () => {
                    Swal.close();
                    let error_message = 'Failed to get billing information';

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        }
    }
};