<div>
    <h5 class="text-s text-center"><strong>RSA ID Verification</strong></h5>
    <div class="row mt-2 mb-3">
      <div class="col-12 text-start">
        <label class="form-label text-xs fw-bold">ID Number <span class="text-danger fw-bold">*</span></label>
        <input v-model="id_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. 0111230000082">
        <small class="text-danger fw-bold text-xs d-none id-message">
          Please fill in this field
        </small>
        <small class="text-danger fw-bold text-xs d-none invalid-message">
          Please enter a valid ID number
        </small>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-6 text-start fw-bold">
        <label class="form-label text-xs">First Names <span class="text-danger fw-bold">*</span></label>
        <input v-model="first_names" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. Mary Jane">
        <small class="text-danger fw-bold text-xs d-none first-name-message">
          Please enter a valid first name(s)
        </small>
      </div>
      <div class="col-6 text-start fw-bold">
        <label class="form-label text-xs">Last Name <span class="text-danger fw-bold">*</span></label>
        <input v-model="last_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="eg. Doe">
        <small class="text-danger fw-bold text-xs d-none last-name-message">
          Please enter a valid last name
        </small>
      </div>
    </div>
    <div class="col text-end">
      <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold" @click.prevent="submit_form">Submit</button>
    </div>
  </div>