<form method="post">
  <!-- Header Section -->
  <div class="text-center">
    <div class="mb-3">
      <p class="text-dark fw-bold text-md">Enter OTP</p>
      <p class="text-dark text-xs">
        Please enter the one-time pin sent to your email to verify your account
      </p>
    </div>
  </div>

  <!-- OTP Input Fields -->
  <div class="otp-container">
    <input
      v-for="(digit, index) in 5"
      :key="index"
      type="text"
      class="otp-input"
      maxlength="1"
      :id="`otp-${index}`"
      v-model="otp_digits[index]"
      @input="handle_otp_input($event, index)"
      @keydown="handle_key_down($event, index)"
      @paste="handle_paste"
      :disabled="is_loading"
      inputmode="numeric"
      pattern="[0-9]*"
    />
  </div>

  <!-- Error Messages -->
  <div class="messages-container">
    <small 
      v-if="error" 
      class="text-danger p-2 text-xxs fw-bold"
    >
      {{ error }}
    </small>
    <small 
      v-if="show_resend_message" 
      class="text-success p-2 text-xxs fw-bold"
    >
      OTP has been resent
    </small>
  </div>

  <!-- Dynamic Questions Component -->
  <div>
    <get_questions
      :client_admin_id="client_admin_id"
      ref="get_questions"
      page_type="otp"
      @update-responses="store_responses"
    />
  </div>

  <!-- Resend OTP Section -->
  <div class="mt-3 mb-2">
    <span class="text-xs">
      Did not receive OTP?
      <a 
        class="text-primary text-xs cursor-pointer" 
        @click="resend_otp"
        :class="{ 'disabled': is_loading }"
      >
        Resend OTP
      </a>
    </span>
  </div>
</form>