<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
	<BreadCrumb label="Proof Of Income Online" />
  
    <div class="d-flex flex-column align-items-center  min-vh-50">
      <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
        <div class="form-group mt-3">
          <label for="id_number" class="fw-bold text-xs text-start d-block">ID Number <span class="text-danger">*</span></label>
          <input
            v-model="id_number"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="id_number"
            placeholder="Enter an ID Number"
            @input="handle_input"
            maxlength="13"
            required
            :disabled="is_field_disabled.id_number"
          />
          <small v-if="error_messages.id_number" class="form-text text-danger">
            {{ error_messages.id_number }}
          </small>

          <label for="account_number" class="fw-bold text-xs text-start d-block mt-3">Bank Account Number <span class="text-danger">*</span></label>
          <input
            v-model="account_number"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="account_number"
            placeholder="e.g., 13XXXXX78"
            required
          />
          <small v-if="error_messages.account_number" class="form-text text-danger">
            {{ error_messages.account_number }}
          </small>

          <label class="fw-bold text-xs text-start d-block mt-3">Select Bank<span class="text-danger">*</span></label>
          <select v-model='bank_name' class="form-select mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm" required>
            <option value="" selected disabled hidden>Select Your Bank</option>
            <option value="absa">Absa</option>
            <option value="capitec">Capitec</option>
            <option value="fnb">Fnb</option>
            <option value="investec">Investec</option>
            <option value="nedbank">Nedbank</option>
            <option value="standard_bank">Standard Bank</option>
            <option value="tyme_bank">Tyme</option>
          </select>
          <small v-if="error_messages.bank_name" class="form-text text-danger">
            {{ error_messages.bank_name }}
          </small>

        </div>
      </div>

  
      <!-- Submit Button -->
      <div class="text-center mb-3 mt-4">
        <button
          class="btn btn-sm bg-green text-dark rounded-small fw-bold secondary-text-dark shadow-sm"
          @click="online_proof_of_income">
          Continue
        </button>
      </div>
    </div>
  </div>
  