import Swal from 'sweetalert2';
import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';
import { handle_registration_redirect } from '@/utils/redirects';
import TermsModal from '@/components/Terms/TermsModal.vue';
import MarketingConsentModal from '@/components/MarketingConsent/MarketingConsentModal.vue';

export default {
    name: 'UserRegistration',
    components: {
        GetQuestions,
        TermsModal,
        MarketingConsentModal
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            password: '',
            confirm_password: '',
            phone_number_error: false,
            phone_error_message: '',
            show_password: false,
            show_confirm_password: false,
            client_admin_id: sessionStorage.getItem('client_admin_id'),
            responses: [],
            follow_up_responses: [],
            password_strength: '',
            password_field_active: false,
            acceptTerms: false,
            acceptMarketing: false,
            clientName: '',
            client: '',
            updatingConsent: false,
            requirements: {
                lowercase: false,
                uppercase: false,
                numeric: false,
                length: false,
                special: false
            },
            iti: null,
            error_timeout: null
        };
    },

    created() {
        const domain = window.location.hostname;

        if (domain.includes("verify")) {
            this.client = "verify";
            this.clientName = "Verify";
        } else if (domain.includes("wizzit")) {
            this.client = "wizzit";
            this.clientName = "Wizzit";
        } else if (domain.includes("mobiafrica")) {
            this.client = "mobiafrica";
            this.clientName = "Mobi Africa";
        } else if (domain.includes("vodacom")) {
            this.client = "vodacom";
            this.clientName = "Vodacom";
        } else if (domain.includes("weelee")) {
            this.client = "weelee";
            this.clientName = "Weelee";
        } else if (domain.includes("nutun")) {
            this.client = "nutun";
            this.clientName = "Nutun";
        } else if (domain.includes("nurturelink")) {
            this.client = "nurturelink";
            this.clientName = "Nurture Link";
        } else if (domain.includes("suzuki")) {
            this.client = "suzuki";
            this.clientName = "Suzuki Bassonia";
        } else if (domain.includes("veersgroup")) {
            this.client = "veersgroup";
            this.clientName = "Veers Group";
        } else if (domain.includes("eaglecorner")) {
            this.client = "eaglecorner";
            this.clientName = "Eagle Corner";
        }
    },

    computed: {
        strength_bar_class() {
            return {
                'weak': this.password_strength === 'Weak',
                'medium': this.password_strength === 'Medium',
                'strong': this.password_strength === 'Strong'
            }
        },
        strength_text_class() {
            return {
                'text-danger': this.password_strength === 'Weak',
                'text-warning': this.password_strength === 'Medium',
                'text-success': this.password_strength === 'Strong'
            }
        },
        passwords_match() {
            return this.password === this.confirm_password;
        }
    },

    mounted() {
        const get_client_id = sessionStorage.getItem('client_admin_id');
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id');

        if (token && user_id) {
            this.check_and_redirect(token, get_client_id);
        }

        this.$nextTick(() => {
            this.initialize_phone_input();
        });
    },

    beforeDestroy() {
        if (this.iti) {
            this.iti.destroy();
        }
        if (this.error_timeout) {
            clearTimeout(this.error_timeout);
        }
    },

    methods: {
        show_error(selector, message, duration = 3000) {
            const $element = $(selector);
            $element.text(message).removeClass('d-none').show();
            if (this.error_timeout) {
                clearTimeout(this.error_timeout);
            }
            this.error_timeout = setTimeout(() => {
                $element.addClass('d-none');
            }, duration);
        },

        openTermsModal() {
            if (this.$refs.termsModal) {
                this.$refs.termsModal.showModal = true;
            }
        },

        openMarketingModal() {
            if (this.$refs.marketingConsentModal) {
                this.$refs.marketingConsentModal.showModal = true;
            }
        },

        handleTermsAccept() {
            this.acceptTerms = true;  // Only update terms checkbox
        },

        handleTermsDecline() {
            this.acceptTerms = false;  // Only update terms checkbox
        },

        handleMarketingAccept() {
            this.acceptMarketing = true;  // Only update marketing checkbox
        },
        handleMarketingDecline() {
            this.acceptMarketing = false;  // Only update marketing checkbox
        },
        async update_customer_consent(consentType, isAccepted) {
            if (this.updatingConsent) return;

            const user_id = localStorage.getItem('user_id');
            if (!user_id) return;

            this.updatingConsent = true;
            const consent_type_id = consentType === 'indemnity' ? 6 : 5;

            try {
                await $.ajax({
                    type: "POST",
                    url: `${process.env.API_BASE_URL}/verify_api/system_management_api/update_consent/`,
                    data: JSON.stringify({
                        user_id: user_id,
                        consent_type_id: consent_type_id,
                        consent_provided: isAccepted
                    }),
                    contentType: 'application/json',
                    headers: {
                        Authorization: `Token ${localStorage.getItem('token')}`
                    }
                });

                // Update local state
                if (consentType === 'indemnity') {
                    this.acceptTerms = isAccepted;
                } else {
                    this.acceptMarketing = isAccepted;
                }
            } catch (error) {
                // Show error message to user
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to update consent. Please try again.'
                });
            } finally {
                this.updatingConsent = false;
            }
        },

        validate_password() {
            const requirements = {
                lowercase: { test: /[a-z]/.test(this.password), message: 'Include lowercase letter' },
                uppercase: { test: /[A-Z]/.test(this.password), message: 'Include uppercase letter' },
                numeric: { test: /[0-9]/.test(this.password), message: 'Include number' },
                special: { test: /[!@#$%^&*]/.test(this.password), message: 'Include special character' },
                length: { test: this.password.length >= 8, message: 'Minimum 8 characters' }
            };

            for (const [key, value] of Object.entries(requirements)) {
                this.requirements[key] = value.test;
                if (!value.test) {
                    this.show_error('.password-requirements-message', value.message);
                    return false;
                }
                this.requirements[key] = value.test;
            }
            return true;
        },

        phone_number_validation() {
            if (!this.iti) return;

            let input_value = this.iti.getNumber().replace(/\D/g, '');
            if (input_value.startsWith('27')) {
                input_value = input_value.substring(2);
            }
            if (!input_value.startsWith('0')) {
                input_value = '0' + input_value;
            }
            input_value = input_value.substring(0, 10);

            const is_valid = /^0\d{9}$/.test(input_value);
            this.phone_number_error = !is_valid;
            this.phone_error_message = is_valid ? '' : 'Please enter a valid phone number';

            if (is_valid) {
                this.phone_number = input_value;
            }

            const input = document.querySelector("#phone-input");
            if (input) {
                input.value = input_value;
            }
        },

        initialize_phone_input() {
            const input = document.querySelector("#phone-input");
            if (input && window.intlTelInput) {
                this.iti = window.intlTelInput(input, {
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
                    separateDialCode: true,
                    formatOnDisplay: true,
                    autoPlaceholder: 'aggressive',
                    onlyCountries: ['za'],
                    initialCountry: 'za',
                });

                input.addEventListener('countrychange', this.phone_number_validation);
                input.addEventListener('input', this.phone_number_validation);
                input.addEventListener('blur', this.phone_number_validation);
            }
        },

        toggle_password_view() {
            this.show_password = !this.show_password;
        },

        toggle_confirm_password_view() {
            this.show_confirm_password = !this.show_confirm_password;
        },

        convert_email_to_lowercase() {
            if (this.email) {
                this.email = this.email.toLowerCase();
            }
        },

        check_and_redirect(token, get_client_id) {
            const check_process_url = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;

            $.ajax({
                url: check_process_url,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                success: (response) => {
                    handle_registration_redirect(
                        response,
                        this.$router,
                        this.$store,
                        this.fetch_current_service,
                        get_client_id
                    );
                },
                error: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was an error while redirecting',
                        confirmButtonText: 'OK'
                    });
                }
            });
        },

        check_password_strength() {
            this.validate_password();
            const met_requirements = Object.values(this.requirements).filter(Boolean).length;

            if (met_requirements <= 2) {
                this.password_strength = 'Weak';
            } else if (met_requirements <= 4) {
                this.password_strength = 'Medium';
            } else {
                this.password_strength = 'Strong';
            }
        },

        password_match() {
            const matches = this.password === this.confirm_password;
            if (!matches) {
                this.show_error('.match-password-message', 'Passwords do not match');
            }
            this.check_password_strength();
            return matches;
        },

        validate_name(field_name) {
            let input_value = this[field_name];
            input_value = input_value.replace(/[^a-zA-Z\s]/g, '');
            input_value = input_value
                .split(' ')
                .filter(word => word.length > 0)
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
            this[field_name] = input_value;
        },

        store_responses(dynamic_fields, follow_up_responses = []) {
            this.responses = dynamic_fields;
            this.follow_up_responses = follow_up_responses;
        },

        validate_inputs() {
            const validations = [
                { condition: !this.first_name.trim(), selector: '.first-name-message', message: 'Please fill in your first name' },
                { condition: !this.last_name.trim(), selector: '.last-name-message', message: 'Please fill in your last name' },
                { condition: !this.email.trim(), selector: '.email-message', message: 'Please fill in your email address' },
                { condition: !this.phone_number.trim(), selector: '.phone-number-message', message: 'Please enter your phone number' },
                { condition: !this.password.trim(), selector: '.password-message', message: 'Please create your own password' },
                { condition: !this.confirm_password.trim(), selector: '.confirm-pswd-message', message: 'Please confirm your password' }
            ];

            for (const validation of validations) {
                if (validation.condition) {
                    this.show_error(validation.selector, validation.message);

                    return false;
                }
            }

            if (!this.validate_password()) {
                return false;
            }

            if (!this.password_match()) {
                return false;
            }

            if (!this.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
                this.email_error = true;
                this.email_error_message = 'Please enter a valid email address';
                return false;
            }

            if (!this.phone_number.trim() || !/^0\d{9}$/.test(this.phone_number)) {
                this.phone_number_error = true;
                this.phone_error_message = 'Please enter a valid 10-digit South African phone number';
                return false;
            }

            return true;
        },

        handle_register() {
            if (this.validate_inputs()) {
                const client_admin_id = sessionStorage.getItem('client_admin_id');
                const customer_application = sessionStorage.getItem('customer_application');
                if ((client_admin_id && client_admin_id !== "null") || (customer_application && customer_application !== "null")) {
                    this.user_registration();
                } else {
                    this.client_registration();
                }
            }
        },

        process_responses() {
            let processed_responses = [];

            this.responses.forEach(field => {

                if (field.has_file && field.value instanceof File) {
                    processed_responses.push({
                        service_id: field.service_id,
                        question_id: field.question_id,
                        value: field.value.name,
                        uploaded_file: field.value
                    });
                } else {
                    processed_responses.push({
                        question_id: field.question_id,
                        value: field.value
                    })
                }
                const follow_ups = this.follow_up_responses.filter(
                    follow_up => follow_up.parent_id === field.question_id
                );

                if (follow_ups.length > 0) {
                    follow_ups.forEach(follow_up => {

                        if (follow_up.has_file && follow_up.value instanceof File) {
                            processed_responses.push({
                                question_id: follow_up.question_id,
                                value: follow_up.value.name,
                                uploaded_file: follow_up.value
                            });
                        } else {
                            processed_responses.push({
                                question_id: follow_up.question_id,
                                value: follow_up.value,
                            });
                        }
                        // If the follow-up contains a file, push it separately
                    });
                }
            });
            return processed_responses;
        },

        handle_registration_error(xhr, error_data) {
            Swal.close();

            // Clear any existing error messages
            $('.email-exists-message, .mobile-exists-message, .email-error-message, .error-message').addClass('d-none');

            if (error_data) {
                if (error_data.email_exists_error) {
                    this.show_error('.email-exists-message', error_data.email_exists_error);
                } else if (error_data.phone_number_exists_error) {
                    this.show_error('.mobile-exists-message', error_data.phone_number_exists_error);
                } else if (error_data.email) {
                    if (typeof error_data.email === 'string') {
                        this.show_error('.email-error-message', error_data.email);
                    } else if (Array.isArray(error_data.email)) {
                        this.show_error('.email-error-message', error_data.email[0]);
                    }
                } else if (error_data.error) {
                    this.show_error('.error-message', error_data.error);
                } else {
                    this.show_error('.error-message', 'An error occurred during registration.');
                }
            }
        },
        handle_registration_success(response) {
            Swal.close();
            localStorage.setItem('user_id', response.user_id);
            localStorage.setItem('token', response.token);
            this.$router.push('/user_otp');
        },

        client_registration() {
            if (!this.validate_inputs() || !this.passwords_match) {
                return;
            }

            const base_url = process.env.API_BASE_URL;
            const registration_url = `${base_url}/verify_api/system_management_api/register_api/`;
            const form_data = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone_number: this.phone_number,
                password: this.password,
                confirm_password: this.confirm_password,
                ...(this.client === 'suzuki' && this.client_admin_id && this.client_admin_id !== 'null' ? {
                    accept_terms: JSON.stringify(this.acceptTerms),
                    accept_marketing: this.acceptMarketing
                } : {})
            };

            $.ajax({
                type: "POST",
                url: registration_url,
                data: JSON.stringify(form_data),
                contentType: 'application/json',
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: this.handle_registration_success,
                error: (xhr) => {
                    let error_data;
                    try {
                        // Try to parse the response as JSON
                        error_data = xhr.responseJSON || (xhr.responseText ? JSON.parse(xhr.responseText) : null);
                    } catch (e) {
                        // If parsing fails, create a generic error object
                        error_data = null;
                    }
                    this.handle_registration_error(xhr, error_data);
                }
            });
        },

        user_registration() {
            if (!this.validate_inputs() ||
                !this.passwords_match ||
                !this.$refs.get_questions.validate_form() ||
                this.handle_registration_error()) {
                return;
            }

            const get_client_id = sessionStorage.getItem('client_admin_id');
            const customer_application = sessionStorage.getItem('customer_application');
            const get_asset_id = sessionStorage.getItem('asset_id');
            const get_workflow_id = sessionStorage.getItem('workflow_id');
            const base_url = process.env.API_BASE_URL;
            const customer_registration_url = `${base_url}/verify_api/system_management_api/register_customer_api/`;

            const all_responses = this.process_responses();
            const has_file = this.responses.some(field => field.has_file);

            let data_to_send;
            let content_type = 'application/json';
            let process_data = true;
            
            const form_data_obj = new FormData();
            
            
            if (has_file) {
                form_data_obj.append('client_admin_id', get_client_id);
                form_data_obj.append('customer_application', customer_application);
                form_data_obj.append('first_name', this.first_name);
                form_data_obj.append('last_name', this.last_name);
                form_data_obj.append('email', this.email);
                form_data_obj.append('phone_number', this.phone_number);
                form_data_obj.append('password', this.password);
                form_data_obj.append('confirm_password', this.confirm_password);
                form_data_obj.append('workflow_id', get_workflow_id);
                form_data_obj.append('accept_terms', JSON.stringify(this.acceptTerms));
                form_data_obj.append('accept_marketing', JSON.stringify(this.acceptMarketing));

                all_responses.forEach((response, index) => {

                    form_data_obj.append(`responses[${index}][question_id]`, response.question_id);
                    form_data_obj.append(`responses[${index}][value]`, response.value);

                    if (response.service_id) {
                        form_data_obj.append(`responses[${index}][service_id]`, response.service_id);
                    }
                    if (response.uploaded_file) {
                        form_data_obj.append(`responses[${index}][uploaded_file]`, response.uploaded_file);
                    }
                });
                
                data_to_send = form_data_obj;
                content_type = false;
                process_data = false;
            } else {
                data_to_send = JSON.stringify({
                    client_admin_id: get_client_id,
                    customer_application: customer_application,
                    asset_id: get_asset_id,
                    workflow_id: get_workflow_id,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone_number: this.phone_number,
                    password: this.password,
                    confirm_password: this.confirm_password,
                    responses: all_responses,
                    consents: [
                        {
                            consent_type_id: 6,
                            consent_provided: this.acceptTerms
                        },
                        {
                            consent_type_id: 5,
                            consent_provided: this.acceptMarketing
                        }
                    ],

                    accept_terms: this.acceptTerms,
                    accept_marketing: this.acceptMarketing
                });
            }         

            $.ajax({
                method: "POST",
                url: customer_registration_url,
                data: data_to_send,
                processData: process_data,
                contentType: content_type,
                dataType: 'json',
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: this.handle_registration_success,
                error: (xhr) => {
                    let error_data;
                    try {
                        // Try to parse the response as JSON
                        error_data = xhr.responseJSON || (xhr.responseText ? JSON.parse(xhr.responseText) : null);
                    } catch (e) {
                        // If parsing fails, create a generic error object
                        error_data = null;
                    }
                    this.handle_registration_error(xhr, error_data);
                }
            });
        }
    }
};