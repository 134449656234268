<template>
    <div class="chart-container">
        <VuePlotly
            :data="plot_data"
            :layout="chart_layout"
            :options="options"
            :plotly="Plotly"
            @plotly_click="handle_click"
            @plotly_hover="handle_hover"
            @plotly_selected="handle_selected"
        />
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import Plotly from "plotly.js-dist-min";
import { VuePlotly } from "vue3-plotly";

export default defineComponent({
    name: "DualAxis",
    components: { VuePlotly },
    emits: ["plotly_click", "plotly_hover", "plotly_selected"],
    props: {
        labels: {
            type: Array,
            default: () => []
        },
        total_data: {
            type: Array,
            default: () => []
        },
        service_data: {
            type: Array,
            default: () => []
        },
        chart_title: {
            type: String,
            default: "Chart Titie"
        },
        xaxis_title: {
            type: String,
            default: "measure X"
        },
        yaxis_title: {
            type: String,
            default: "Measure y1"
        },
        y2axis_title: {
            type: String,
            default: "Measure y2"
        },
        bar_color: {
            type: String,
            default: "rgba(89, 255, 116, 0.5)"
        },
        line_color: {
            type: String,
            default: "rgba(33, 37, 41, 1)"
        },
        line_width: {
            type: Number,
            default: 2
        },
        responsive: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        // Create bar trace for total costs
        const trace_1 = computed(() => ({
            x: props.labels,
            y: props.total_data,
            type: "bar",
            name: "Total Costs",
            marker: { color: props.bar_color },
            yaxis: "y"
        }));

        // Create line trace for service cost
        const trace_2 = computed(() => ({
            x: props.labels,
            y: props.service_data,
            type: "scatter",
            mode: "lines",
            name: "Service Cost",
            line: { color: props.line_color, width: props.line_width },
            yaxis: "y2"
        }));

        // Combine traces into a single data array
        const plot_data = computed(() => [trace_1.value, trace_2.value]);

        // Define chart layout
        const chart_layout = computed(() => ({
            title: props.chart_title,
            xaxis: { title: props.xaxis_title },
            yaxis: {
                title: props.yaxis_title,
            },
            yaxis2: {
                title: props.y2axis_title,
                overlaying: "y",
                side: "right"
            },
            legend: {
                orientation: "h",
                x: 0.2,
                y: 1.1
            }
        }));

        const options = computed(() => ({ 
            responsive: props.responsive 
        }));

        // Event handlers with proper emit
        const handle_click = (event) => {
            emit("plotly_click", event);
        };

        const handle_hover = (event) => {
            emit("plotly_hover", event);
        };

        const handle_selected = (event) => {
            emit("plotly_selected", event);
        };

        return {
            plot_data,
            chart_layout,
            options,
            Plotly,
            handle_click,
            handle_hover,
            handle_selected
        };
    }
});
</script>
