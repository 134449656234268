import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";
import Swal from 'sweetalert2';
import ClientOverview from '@/layouts/billing/components/overview/ClientOverview.vue';


export default {
    name: 'ClientPerformance',
    components: {
        ClientOverview
    },
    mixins: [LOGOUT],
    data() {
        return {
            // Tab status
            active_tab: 'client_data',

            // Pagination for clients
            search_query: '',
            current_page: 1,
            items_per_page: 10,

            // Pagination for services
            service_current_page: 1,
            service_items_per_page: 5,

            // Pagination for thresholds
            threshold_current_page: 1,
            threshold_items_per_page: 3,

            // Pagination for preview
            preview_current_page: 1,
            preview_items_per_page: 3,

            // Client data for the table
            clients: [],

            // All services data (unfiltered)
            all_client_services: [],

            // Client services data (filtered for selected client)
            client_services: [],

            // Client detail view
            selected_client_detail: null,
            selected_service: null,
            edited_service: null,
        };
    },
    computed: {
        filtered_clients() {
            let filtered = this.clients.filter(client => {
                return client.name.toLowerCase().includes(this.search_query.toLowerCase()) ||
                    client.total_cost.toString().includes(this.search_query) ||
                    client.revenue.toString().includes(this.search_query) ||
                    client.no.toString().includes(this.search_query);
            });
            return filtered.slice((this.current_page - 1) * this.items_per_page, this.current_page * this.items_per_page);
        },
        total_pages() {
            return Math.ceil(this.clients.length / this.items_per_page);
        },

        // New computed properties for service pagination
        paginated_client_services() {
            const start = (this.service_current_page - 1) * this.service_items_per_page;
            const end = start + this.service_items_per_page;
            return this.client_services.slice(start, end);
        },
        service_total_pages() {
            return Math.ceil(this.client_services.length / this.service_items_per_page);
        },

        // New computed properties for threshold pagination
        paginated_volume_thresholds() {
            if (!this.edited_service) return [];
            const start = (this.threshold_current_page - 1) * this.threshold_items_per_page;
            const end = start + this.threshold_items_per_page;
            return this.edited_service.volume_thresholds.slice(start, end);
        },
        threshold_total_pages() {
            if (!this.edited_service) return 0;
            return Math.ceil(this.edited_service.volume_thresholds.length / this.threshold_items_per_page);
        },

        // New computed properties for preview pagination
        paginated_sorted_thresholds() {
            if (!this.edited_service) return [];
            const sorted_thresholds = this.get_sorted_thresholds();
            const start = (this.preview_current_page - 1) * this.preview_items_per_page;
            const end = start + this.preview_items_per_page;
            return sorted_thresholds.slice(start, end);
        },
        preview_total_pages() {
            if (!this.edited_service) return 0;
            return Math.ceil(this.get_sorted_thresholds().length / this.preview_items_per_page);
        }
    },
    watch: {
        selected_client_detail() {
            if (this.selected_client_detail) {
                // Reset service pagination when showing client detail
                this.service_current_page = 1;
            }
        },
        'edited_service.volume_thresholds': {
            handler() {
                // Reset threshold pagination if needed
                if (this.edited_service && this.threshold_current_page > this.threshold_total_pages) {
                    this.threshold_current_page = Math.max(1, this.threshold_total_pages);
                }
            },
            deep: true
        }
    },
    methods: {
        /**
         * Fetches client information and their associated services from the API
         * Initializes client data and processes service information
         */
        get_client_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_SERVICE_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/get_all_clients/`;

            $.ajax({
                url: GET_SERVICE_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }

                    this.clients = response.data.map((element, index) => {
                        return {
                            no: index + 1,
                            name: element.email,
                            total_cost: 0,
                            revenue: 0,
                            id: element.id,
                            last_login: element.last_login
                        }
                    });

                    // Reset services array
                    this.all_client_services = [];

                    // Process services for each client
                    response.data.forEach((client, client_index) => {
                        if (!client.services || !Array.isArray(client.services)) {
                            return; // Skip this client
                        }

                        // Process each service for this client
                        client.services.forEach(service => {
                            // Parse cost and revenue values with error handling
                            const cost = this.parse_numeric_value(service.cost);
                            const revenue = this.parse_numeric_value(service.revenue);
                            const price_per_run = this.parse_numeric_value(service.price_per_run);
                            const base_cost = this.parse_numeric_value(service.base_cost);

                            // Add to client totals
                            this.clients[client_index].total_cost += cost;
                            this.clients[client_index].revenue += revenue;

                            // Add service details to all_client_services array
                            this.all_client_services.push({
                                client_id: client.id,
                                service_id: service.service_id,
                                name: service.name || service.service_name,
                                usage: service.usage || 0,
                                cost: cost,
                                revenue: revenue,
                                price_per_run: price_per_run,
                                base_cost: base_cost,
                                status: service.status || 'Inactive',
                                volume_thresholds: Array.isArray(service.volume_thresholds) ? service.volume_thresholds : []
                            });
                        });

                        // Format totals with 2 decimal places
                        this.clients[client_index].total_cost = Number(this.clients[client_index].total_cost).toFixed(2);
                        this.clients[client_index].revenue = Number(this.clients[client_index].revenue).toFixed(2);
                    });
                },
                error: () => {
                    Swal.close();
                    let error_message = 'Failed to get client information';

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
        /**
         * Changes the active tab in the component
         * @param {string} tab_name - The name of the tab to activate
         */
        switch_tab(tab_name) {
            this.active_tab = tab_name;
        },
        /**
         * Navigates to the previous page of the client list
         */
        previous_page() {
            if (this.current_page > 1) {
                this.current_page--;
            }
        },
        /**
         * Navigates to the next page of the client list
         */
        next_page() {
            if (this.current_page < this.total_pages) {
                this.current_page++;
            }
        },
        /**
         * Displays the details of the selected client
         * @param {Object} client - The selected client
         */
        show_client_detail(client) {
            this.selected_client_detail = client;

            // Filter client_services array to only include services for the selected client
            this.client_services = this.all_client_services.filter(service => service.client_id === client.id);
        },
        /**
         * Closes the client detail view
         */
        close_client_detail() {
            this.selected_client_detail = null;
        },
        /**
         * Edits the selected service
         * @param {Object} service - The selected service
         */
        edit_service(service) {
            console.log(service)
            this.selected_service = service;
            this.edited_service = service;
        },
        /**
         * Closes the service detail view
         */
        close_service_detail() {
            this.selected_service = null;
            this.edited_service = null;
        },
        /**
         * Saves the changes made to the service
         */
        save_service_changes() {
            // Check if we have a valid service to save
            if (!this.edited_service) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No service selected for editing',
                    confirmButtonText: 'OK'
                });
                return;
            }

            if (this.edited_service.price_per_run <= 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Invalid Price',
                    text: 'Please enter a valid price per run',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                return;
            }

            const TOKEN = localStorage.getItem('token');
            const SERVICE_PRICE_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/create_client_price/`;

            // Prepare data for API
            const request_data = {
                client_id: this.selected_client_detail.id,
                service_id: this.edited_service.service_id,
                price: this.edited_service.price_per_run
            };

            $.ajax({
                url: SERVICE_PRICE_API,
                method: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(request_data),
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        title: 'Saving changes...',
                        text: 'Please wait',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();

                    if (response.redirect) {
                        this.logout();
                        return;
                    }

                    if (response.status === 'success') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Service pricing updated successfully',
                            confirmButtonText: 'OK'
                        });

                        this.get_client_information();

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.message || 'Failed to update service pricing',
                            confirmButtonText: 'OK'
                        });
                    }
                },
                error: (xhr) => {
                    Swal.close();
                    let error_message = 'Failed to update service pricing';

                    try {
                        const response = JSON.parse(xhr.responseText);
                        if (response.message) {
                            error_message = response.message;
                        }

                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error_message,
                            confirmButtonText: 'OK'
                        });

                    } catch {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error_message,
                            confirmButtonText: 'OK'
                        });
                    }
                }
            });

        },
        /**
         * Adds a new volume pricing threshold
         */
        add_threshold() {
            this.edited_service.volume_thresholds.push({
                id: Date.now(),
                threshold: 1,
                discount: 0,
                type: 'absolute'
            });
        },
        /**
         * Save threshold created for client.
         */
        save_threshold() {
            if (!this.edited_service || this.edited_service.volume_thresholds.length === 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'No Thresholds',
                    text: 'Please add at least one volume threshold before saving',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                })
                return;
            }

            if (this.edited_service.volume_thresholds.length === 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'No Thresholds',
                    text: 'Please add at least one volume threshold before saving',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                })
                return;
            }

            if (this.edited_service.volume_thresholds.length === 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'No Thresholds',
                    text: 'Please add at least one volume threshold before saving',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                })
                return;
            }

            const TOKEN = localStorage.getItem('token');
            const VOLUME_PRICE_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/bulk_create_volume/`;

            // Prepare data for API
            const request_data = {
                client_id: this.selected_client_detail.id,
                service_id: this.edited_service.service_id,
                volume_thresholds: this.edited_service.volume_thresholds.map(threshold => ({
                    volume: threshold.threshold,
                    discount_value: threshold.discount,
                    type: threshold.type
                }))
            };

            $.ajax({
                url: VOLUME_PRICE_API,
                method: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(request_data),
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        title: 'Saving thresholds...',
                        text: 'Please wait',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();

                    if (response.redirect) {
                        this.logout();
                        return;
                    }

                    if (response.status === 'success') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Volume thresholds saved successfully',
                            confirmButtonText: 'OK'
                        });
                        this.get_client_information();

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.message || 'Failed to save volume thresholds',
                            confirmButtonText: 'OK'
                        });
                    }
                },
                error: (xhr) => {
                    Swal.close();
                    let error_message = 'Failed to save volume thresholds';

                    try {
                        const response = JSON.parse(xhr.responseText);
                        if (response.message) {
                            error_message = response.message;
                        }
                    } catch {
                        // Use default error message
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
        /**
         * Removes a volume pricing threshold
         * @param {number} index - The index of the threshold to remove
         */
        remove_threshold(index) {
            this.edited_service.volume_thresholds.splice(index, 1);

            // If the current page becomes empty, go to previous page
            if (this.paginated_volume_thresholds.length === 0 && this.threshold_current_page > 1) {
                this.threshold_current_page--;
            }
        },
        /**
         * Calculates the average cost of client services
         * @return {number} - The average cost
         */
        calculate_average_cost() {
            if (!this.client_services.length) return 0;
            const total_cost = this.client_services.reduce((sum, service) => sum + service.cost, 0);
            return (total_cost / this.client_services.length).toFixed(2);
        },
        /**
         * Calculates the profit margin of client services
         * @return {number} - The profit margin
         */
        calculate_profit_margin() {
            if (!this.client_services.length) return 0;
            const total_revenue = this.client_services.reduce((sum, service) => sum + service.revenue, 0);
            const total_cost = this.client_services.reduce((sum, service) => sum + service.cost, 0);
            
            // Check for zero revenue to avoid division by zero
            if (total_revenue === 0) return 0; // or another appropriate value
            
            return ((total_revenue - total_cost) / total_revenue * 100).toFixed(2);
        },
        /**
         * Calculates the profit margin of the selected service
         * @return {number} - The profit margin
         */
        calculate_service_profit_margin() {
            if (!this.edited_service) return 0;
            const client_revenue = (this.edited_service.price_per_run || 0) * this.edited_service.usage;
            const base_cost = this.edited_service.base_cost * this.edited_service.usage;

            if (client_revenue === 0) return 0;

            return ((client_revenue - base_cost) / client_revenue * 100).toFixed(2);
        },
        /**
         * Calculates the discount for a given price per run
         * @param {Object} threshold - The threshold object
         * @return {number} - The discount percentage
         */
        calculate_discount(threshold) {
            if (!this.edited_service) return 0;

            if (threshold.type === 'percentage') {
                return threshold.discount.toFixed(2);
            } else {
                const base_price = this.edited_service.price_per_run;
                return ((base_price - threshold.discount) / base_price * 100).toFixed(2);
            }
        },
        /**
         * Gets the CSS class for the discount color based on the discount percentage
         * @param {Object} threshold - The threshold object
         * @return {string} - The CSS class for the discount color
         */
        get_discount_color_class(threshold) {
            const discount = threshold.type === 'percentage' ?
                threshold.discount :
                this.calculate_discount(threshold);

            if (discount > 50) return 'bg-success';
            if (discount > 20) return 'bg-warning';
            return 'bg-danger';
        },
        /**
         * Gets the volume of the first threshold
         * @return {number} - The volume of the first threshold
         */
        get_first_threshold_volume() {
            if (!this.edited_service || !this.edited_service.volume_thresholds.length) return 1;
            return this.edited_service.volume_thresholds[0].threshold;
        },
        /**
         * Gets the volume of the next threshold
         * @param {number} index - The index of the current threshold
         * @return {number|null} - The volume of the next threshold or null if there is no next threshold
         */
        get_next_threshold_volume(index) {
            if (!this.edited_service || index >= this.edited_service.volume_thresholds.length - 1) return null;
            return this.edited_service.volume_thresholds[index + 1].threshold;
        },
        /**
         * Gets the sorted volume thresholds
         * @return {Array} - The sorted volume thresholds
         */
        get_sorted_thresholds() {
            if (!this.edited_service) return [];
            return this.edited_service.volume_thresholds.slice().sort((a, b) => a.threshold - b.threshold);
        },
        /**
         * Calculates the total base cost of the selected service
         * @return {number} - The total base cost
         */
        calculate_base_cost_total() {
            if (!this.edited_service) return 0;
            return (this.edited_service.base_cost * this.edited_service.usage).toFixed(2);
        },
        /**
         * Calculates the total client revenue of the selected service
         * @return {number} - The total client revenue
         */
        calculate_client_revenue_total() {
            if (!this.edited_service) return 0;
            return (this.edited_service.price_per_run * this.edited_service.usage).toFixed(2);
        },
        /**
         * Calculates the net revenue of the selected service
         * @return {number} - The net revenue
         */
        calculate_net_revenue() {
            if (!this.edited_service) return 0;
            return (this.calculate_client_revenue_total() - this.calculate_base_cost_total()).toFixed(2);
        },
        /**
         * Navigates to the next page of the client services list
         */
        next_service_page() {
            if (this.service_current_page < this.service_total_pages) {
                this.service_current_page++;
            }
        },
        /**
         * Navigates to the previous page of the client services list
         */
        previous_service_page() {
            if (this.service_current_page > 1) {
                this.service_current_page--;
            }
        },
        /**
         * Navigates to the next page of the volume thresholds list
         */
        next_threshold_page() {
            if (this.threshold_current_page < this.threshold_total_pages) {
                this.threshold_current_page++;
            }
        },
        /**
         * Navigates to the previous page of the volume thresholds list
         */
        previous_threshold_page() {
            if (this.threshold_current_page > 1) {
                this.threshold_current_page--;
            }
        },
        /**
         * Navigates to the next page of the price structure preview list
         */
        next_preview_page() {
            if (this.preview_current_page < this.preview_total_pages) {
                this.preview_current_page++;
            }
        },
        /**
         * Navigates to the previous page of the price structure preview list
         */
        previous_preview_page() {
            if (this.preview_current_page > 1) {
                this.preview_current_page--;
            }
        },
        /**
         * Gets the actual index of a threshold in the volume thresholds array
         * @param {number} displayed_index - The displayed index of the threshold
         * @return {number} - The actual index of the threshold
         */
        get_actual_threshold_index(displayed_index) {
            const start_index = (this.threshold_current_page - 1) * this.threshold_items_per_page;
            return start_index + displayed_index;
        },
        /**
         * Gets the actual index of a threshold in the price structure preview array
         * @param {number} displayed_index - The displayed index of the threshold
         * @return {number} - The actual index of the threshold
         */
        get_actual_preview_index(displayed_index) {
            const start_index = (this.preview_current_page - 1) * this.preview_items_per_page;
            return start_index + displayed_index;
        },
        /**
         * Safely parses a numeric value from various input types
         * @param {*} value - The value to parse
         * @param {number} default_value - Value to return if parsing fails (default: 0)
         * @return {number} - The parsed number or default value
         */
        parse_numeric_value(value, default_value = 0) {
            // Handle empty strings, null or undefined
            if (value === '' || value === null || value === undefined) {
                return default_value;
            }

            // Convert to number
            const numeric_value = Number(value);

            // Check if conversion resulted in a valid number
            if (isNaN(numeric_value)) {
                return default_value;
            }

            return numeric_value;
        },

    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_client_information();
    },
    beforeUnmount() {
        // Clear any running animations when component is destroyed
        if (this.count_interval) {
            clearInterval(this.count_interval);
        }
    }
};