import { validateToken } from "@/utils/auth";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net";
import { LOGOUT } from "@/utils/logout.js";

export default {
  name: "ConditionalQuestions",
  mixins: [LOGOUT],
  data() {
    return {
      question_id: null,
      question: null,
      options: [],
      questions: {},
      loading: false
    };
  },
  methods: {
    edit_follow_up_question(follow_up_question, option_id) {
      const TOKEN = localStorage.getItem("token");
      const EDIT_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/update_question_api/`;

      // Validation
      if (follow_up_question.question === "") {
        Swal.fire({
          text: "Please fill in the question field.",
          icon: "warning",
        });
        return;
      }
      if (follow_up_question.number === "") {
        Swal.fire({
          text: "Please fill in the question number field.",
          icon: "warning",
        });
        return;
      }
      if (follow_up_question.question_type === "") {
        Swal.fire({
          text: "Please select a question type.",
          icon: "warning",
        });
        return;
      }
      if (follow_up_question.is_required === "") {
        Swal.fire({
          text: "Please specify if the question is mandatory.",
          icon: "warning",
        });
        return;
      }

      if (
        (follow_up_question.question_type === "Selection" ||
          follow_up_question.question_type === "Checkbox") &&
        follow_up_question.options.some((option) => !option)
      ) {
        Swal.fire({
          text: "Please ensure all options are filled in.",
          icon: "warning",
        });
        return;
      }

      const FORM_DATA = {
        question_id: follow_up_question.id,
        question: follow_up_question.question,
        question_number: follow_up_question.number,
        question_type: follow_up_question.question_type,
        mandatory: follow_up_question.is_required,
        options: follow_up_question.options,
      };

      $.ajax({
        url: EDIT_QUESTION_API_URL,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Updating question...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: () => {
          Swal.close();
          Swal.fire({
            icon: "success",
            text: "Question updated successfully.",
            showConfirmButton: false,
            timer: 2000,
          });
          this.get_questions_with_options();
          this.open_option_view(option_id);
        },
        error: () => {
          Swal.fire({
            text: "An error occurred",
            icon: "error",
          });
          Swal.close();
        },
      });
    },
    initDataTable() {
      if ($.fn.dataTable.isDataTable("#follow_up_question_table")) {
        $("#follow_up_question_table").DataTable().destroy();
      }
      $("#follow_up_question_table").DataTable({
        responsive: true,
      });
    },
    get_questions_with_options() {
      const TOKEN = localStorage.getItem("token");
      const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/question_management_api/manage_questions_api/`;

      $.ajax({
        url: GET_QUESTIONS_API,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          if (response.redirect) {
            this.logout();
            return;
          }
          // Find the specific question by ID
          const target_question = response.questions.find(
            (q) => q.id === parseInt(this.question_id)
          );

          if (target_question) {
            this.question = target_question;
            this.options = target_question.options || [];
            if (target_question.options) {
              var list_options = target_question.options.map((option) => {
                if (option.follow_up_question) {
                  option.follow_up_question.map((follow_up_question) => {
                    follow_up_question.number_of_options =
                      follow_up_question.options
                        ? follow_up_question.options.length
                        : 0;

                    follow_up_question.options = follow_up_question.options
                      ? follow_up_question.options.map((opt) => opt.option) // Use map instead of forEach
                      : [];
                  });
                }
                return option;
              });
            }
            this.options = list_options;
          } else {
            Swal.fire({
              icon: "warning",
              title: "Not Found",
              text: `Question not found.`,
              confirmButtonText: "OK",
            });
          }

          this.loading = false;
        },
        error: () => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error fetching the question.",
            confirmButtonText: "OK",
          });
        },
      });
    },
    open_option_view(option_id) {
      setTimeout(() => {
        this.options.forEach((option) => {
          option.show_follow_up = option.id === option_id;
        });
      }, 1000);
    },
    save_question(question, option_id, index) {
      const TOKEN = localStorage.getItem("token");
      const ADD_QUESTION_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/save_question_api/`;

      if (question.question === "") {
        Swal.fire({
          text: "Please fill in the question field.",
          icon: "warning",
        });
        return;
      }
      if (question.question_number === "") {
        Swal.fire({
          text: "Please fill in the question number field.",
          icon: "warning",
        });
        return;
      }
      if (question.question_type === "") {
        Swal.fire({
          text: "Please select a question type.",
          icon: "warning",
        });
        return;
      }
      if (question.mandatory === "") {
        Swal.fire({
          text: "Please specify if the question is mandatory.",
          icon: "warning",
        });
        return;
      }
      if (
        (question.question_type === "Selection" ||
          question.question_type === "Checkbox") &&
          question.options.some((option) => !option)
      ) {
        Swal.fire({
          text: "Please ensure all options are filled in.",
          icon: "warning",
        });
        return;
      }

      var form_data = {
        question: question.question,
        question_number: question.question_number,
        question_type: question.question_type,
        mandatory: question.mandatory,
        options: question.options,
        is_follow_up_question: question.is_follow_up_question,
        option_id: option_id,
        previous_question_id: this.question_id,
      };

      // If all data is valid, proceed with AJAX calls
      const save_questions = async () => {
        try {
          await $.ajax({
            method: "POST",
            url: ADD_QUESTION_URL,
            data: JSON.stringify(form_data),
            contentType: "application/json",
            headers: {
              Authorization: `Token ${TOKEN}`,
            },
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to save the questions. Please try again.",
            confirmButtonText: "OK",
          });
          return;
        }

        Swal.fire({
          icon: "success",
          text: "Question saved successfully.",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
            this.questions[option_id].splice(index, 1);
            this.get_questions_with_options();
            this.open_option_view(option_id);
        });
      };

      save_questions();
      this.open_option_view(option_id);

    },
    add_question(option_id) {
      if (!this.questions[option_id]) {
        this.questions[option_id] = [];
      }
      this.questions[option_id].push({
        question: '',
        question_number: '',
        question_type: '',
        mandatory: '',
        number_of_options: 0,
        options: [],
        is_follow_up_question: true,
      });
    },
    remove_question(option_id, index) {
      if (this.questions[option_id]) {
        this.questions[option_id].splice(index, 1);
      }
    },
    validate_number_of_options(option_id, index) {
      var question = this.questions[option_id][index];
      if (question.number_of_options > 150) {
        question.number_of_options = 150;
        Swal.fire({
          title: "Too Many Options!",
          text: "You cannot enter more than 150 options.",
          icon: "error",
          timer: 2000,
          showConfirmButton: true,
        });
      }
      if (question.number_of_options > 0) {
        // Update the options array dynamically
        this.update_options_array(option_id, index);
      }
      // Update the options array dynamically
    },
    update_options_array(option_id, index) {
      var question = this.questions[option_id][index];

      // If the number of options is reduced, remove excess options
      while (question.options.length > question.number_of_options) {
        question.options.pop();
      }

      // If the number of options is increased, add empty slots
      while (question.options.length < question.number_of_options) {
        question.options.push("");
      }
    },
    edit_validate_number_of_options(option_index, index) {
      var question = this.options[option_index].follow_up_question[index];
      if (question.number_of_options > 150) {
        question.number_of_options = 150;
        Swal.fire({
          title: "Too Many Options!",
          text: "You cannot enter more than 150 options.",
          icon: "error",
          timer: 2000,
          showConfirmButton: true,
        });
      }
      if (question.number_of_options > 0) {
        // Update the options array dynamically
        this.edit_update_options_array(option_index, index);
      }
    },
    edit_update_options_array(option_index, index) {
      var question = this.options[option_index].follow_up_question[index];

      // If the number of options is reduced, remove excess options
      while (question.options.length > question.number_of_options) {
        question.options.pop();
      }

      // If the number of options is increased, add empty slots
      while (question.options.length < question.number_of_options) {
        question.options.push("");
      }
    },
    deactivate_follow_up_question(question_id) {
      const TOKEN = localStorage.getItem("token");
      const DEACTIVATE_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/change_question_status_api/`;

      const FORM_DATA = {
        question_id: question_id,
        question_status: "inactive",
      };

      $.ajax({
        url: DEACTIVATE_QUESTION_API_URL,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Updating question status...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: () => {
          Swal.close();
          this.get_questions_with_options();
        },
        error: () => {
          Swal.fire({
            text: "An error occurred",
            icon: "error",
          });
          Swal.close();
        },
      });
    },
    activate_follow_up_question(question_id) {
      const TOKEN = localStorage.getItem("token");
      const ACTIVATE_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/change_question_status_api/`;

      const FORM_DATA = {
        question_id: question_id,
        question_status: "active",
      };

      $.ajax({
        url: ACTIVATE_QUESTION_API_URL,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Updating question status...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: () => {
          Swal.close();
          this.get_questions_with_options();
        },
        error: () => {
          Swal.fire({
            text: "An error occurred",
            icon: "error",
          });
          Swal.close();
        },
      });
    },
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }

    // Retrieve question_id from route params
    this.question_id = this.$route.params.question_id;

    if (this.question_id) {
      this.get_questions_with_options();
    } 
  },
};
