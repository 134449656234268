<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";
import Plotly from "plotly.js-dist-min";
import { VuePlotly } from "vue3-plotly";

const props = defineProps({
  chart_data: {
    type: Array,
    default: () => [
      {
        x: ["Jan", "Feb", "Mar", "Apr"],
        y: [10, 20, 15, 30],
        type: "scatter",
        mode: "lines",
        line: { color: "rgb(75, 192, 192)", width: 2 },
      },
    ],
  },
  chart_layout: {
    type: Object,
    default: () => ({
      title: "Line Chart",
      xaxis: { title: "X Axis" },
      yaxis: { title: "Y Axis" },
    }),
  },
  chart_options: {
    type: Object,
    default: () => ({
      responsive: true,
    }),
  },
});

const emit = defineEmits(["plotly_click", "plotly_hover", "plotly_selected"]);

const data = ref(props.chart_data);
const layout = ref(props.chart_layout);
const options = ref(props.chart_options);

// Watch for changes in props
watch(() => props.chart_data, (new_val) => {
  data.value = new_val;
}, { deep: true });

watch(() => props.chart_layout, (new_val) => {
  layout.value = new_val;
}, { deep: true });

watch(() => props.chart_options, (new_val) => {
  options.value = new_val;
}, { deep: true });

const on_plotly_click = (event) => {
  emit("plotly_click", event);
};

const on_plotly_hover = (event) => {
  emit("plotly_hover", event);
};

const on_plotly_selected = (event) => {
  emit("plotly_selected", event);
};
</script>

<template>
  <div class="chart-container">
    <VuePlotly
      :data="data"
      :layout="layout"
      :options="options"
      :plotly="Plotly"
      @plotly_click="on_plotly_click"
      @plotly_hover="on_plotly_hover"
      @plotly_selected="on_plotly_selected"
    />
  </div>
</template>

<style scoped>
.chart-container {
  width: 100%;
  height: 400px;
  margin-bottom: 1rem;
}
</style>