<div class="form-container">
  <form method="post" @submit.prevent="login">
    <div class="text-center mb-3">
      <p class="text-dark fw-bold text-md">Login</p>

    </div>
    
    <!-- Email Input -->
    <div class="form-group">
      <div class="form-floating">
        <input
          type="text"
          class="form-control-nb bottom-border-input text-xs"
          placeholder=" "
          v-model="username"
          name="username"
          id="email-input"
        />
        <label for="email-input" class="fw-bold text-xs">Email</label>
      </div>
      <small class="text-danger p-2 text-xxs fw-bold d-none username-message"
        >Please fill in this field</small>
    </div>

    <!-- Password Input -->
    <div class="form-group">
      <div class="form-floating position-relative">
        <input
          class="form-control-nb bottom-border-input text-xs pr-5"
          placeholder=" "
          v-model="password"
          name="password"
          :type="show_password ? 'text' : 'password'"
          id="password-input"
        />
        <label for="password-input" class="fw-bold text-xs">Password</label>
        <span
          class="eye-icon"
          @click="toggle_password_view"
        >
          <i :class="show_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </span>
      </div>
      <small class="text-danger p-2 text-xxs fw-bold d-none password-message"
        >Please fill in this field</small>
    </div>

  <div>
    <GetQuestions
      :client_admin_id="client_admin_id"
      ref="get_questions"
      page_type="login"
      @update-responses="store_responses"
    />
  </div>

  <div v-if="client === 'suzuki'" class="text-center text-xs fw-bold">
    Powered by <a href="http://www.mobiafrica.co.za" class="suzuki-link">www.mobiafrica.co.za</a>
  </div>

    <small class="text-danger p-2 text-xxs fw-bold d-none login-message"></small>
    <div class="mb-3">
      <button
        type="submit"
        class="btn btn-bg-green secondary-text-dark text-xs w-75 fw-bold mt-2"
        @click="route_to"
      >
      {{ client === 'suzuki' ? 'Submit' : 'Login' }}
      </button>
    </div>

    <div class="mt-1 text-center">
      <span class="text-dark text-xs fw-bold"> 
        {{ client === 'suzuki' ? 'Dont have an account? ' : 'Not Registered? ' }}
      </span>
      <a class="text-primary text-xs">
        <router-link class=text-primary to="/user_registration">
          {{ client === 'suzuki' ? 'Sign Up' : 'Create an account' }}
        </router-link>
      </a>
    </div>
    <div class="text-center">
      <span class="text-dark fw-bold text-xs">Forgot password?</span>
      <a :href="reset_url" class="text-primary text-xs"> Reset password</a>
    </div>
  </form>
</div>