<div class="container my-3">
    <div v-if="device_screen >= 998" class="row">
        <!-- Left: GaugeChart Card -->
        <div class="col-md-6 col-lg-4">
            <div class="card rounded-3 shadow-md">
                <div class="card-body d-flex flex-column justify-content-center align-items-center" id="gauge-chart">
                    <GaugeChart v-if="card_screen.width"
                    :data_percent="percentage"
                    :data_text="null"
                    :data_text_size="0.22"
                    :data_prepend="null"
                    :data_append="'%'"
                    :data_size="card_screen.width"
                    :data_width="20"
                    :data_style="'Full'"
                    :data_color="null"
                    :data_back="null"
                    :data_theme="'DarkGreen-LightGreen'"
                    :data_animate_gauge_colors="false"
                    :data_animate_text_colors="false"
                    :data_label="'Active Clients'"
                    :data_label_color="null"
                    :data_stripe="5"
                    />
                </div>
            </div>
        </div>

        <!-- Right: Cards -->
        <div class="col-md-6 col-lg-8">
            <div class="row h-100">
                <div class="col-md-6 col-lg-4 my-1" v-for="card in cards" :key="card.title">
                    <div class="card rounded-3 shadow-md h-100 d-flex flex-column justify-content-center">
                        <div class="card-statistic p-3">
                            <div class="card-icon card-icon-large">
                                <i :class="card.icon"></i>
                            </div>
                            <div class="row d-flex flex-column">
                                <div class="col-sm-12 row align-items-center pb-2 d-flex">
                                    <div class="col-sm-8">
                                        <h4 class="mb-0">
                                            {{ card.value }}
                                        </h4>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="mt-1">
                                        <label class="text-xs mb-0 text-center">{{ card.title }}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="row">
        <!-- Alternative layout: Cards with Active Users and Total Users -->
            <div class="col-md-6 col-lg-4 mb-4">
                <div class="card rounded-3 shadow-md h-100">
                    <div class="card-statistic p-3">
                        <div class="card-icon card-icon-large">
                            <i class="fas fa-user-check"></i>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-8">
                                <h4 class="d-flex align-items-center mb-0 justify-content-center">
                                    {{ active_users }}
                                </h4>
                            </div>
                        </div>
                        <div class="mt-2">
                            <label class="text-xs mb-0 text-center">Active Users</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
                <div class="card rounded-3 shadow-md h-100">
                    <div class="card-statistic p-3">
                        <div class="card-icon card-icon-large">
                            <i class="fas fa-users"></i>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-8">
                                <h4 class="d-flex align-items-center mb-0 justify-content-center">
                                    {{ total_users }}
                                </h4>
                            </div>
                        </div>
                        <div class="mt-2">
                            <label class="text-xs mb-0 text-center">Total Users</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-4" v-for="card in cards" :key="card.title">
                <div class="card rounded-3 shadow-md h-100 d-flex flex-column justify-content-center">
                    <div class="card-statistic p-3">
                        <div class="card-icon card-icon-large">
                            <i :class="card.icon"></i>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-8">
                                <h4 class="d-flex align-items-center mb-0 justify-content-center">
                                    {{ card.value }}
                                </h4>
                            </div>
                        </div>
                        <div class="mt-2">
                            <label class="text-xs mb-0 text-center">{{ card.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>