<template>
  <div class="gauge-chart-container">
    <div class="gauge-meter" :id="chart_id" v-bind="chart_options">
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, toRefs } from "vue";
import '@/lib/gauage/GaugeMeter.js';

export default defineComponent({
  name: "gauge_chart",
  props: {
    data_percent: {
      type: Number,
      required: true,
      default: 0
    },
    data_min: {
      type: Number,
      required: false,
      default: null
    },
    data_used: {
      type: Number,
      required: false,
      default: null
    },
    data_total: {
      type: Number,
      required: false,
      default: null
    },
    data_text: {
      type: String,
      required: false,
      default: null
    },
    data_text_size: {
      type: Number,
      required: false,
      default: 0.22
    },
    data_prepend: {
      type: String,
      required: false,
      default: null
    },
    data_append: {
      type: String,
      required: false,
      default: null
    },
    data_size: {
      type: Number,
      required: false,
      default: 100
    },
    data_width: {
      type: Number,
      required: false,
      default: 3
    },
    data_style: {
      type: String,
      required: false,
      default: 'Full'
    },
    data_color: {
      type: String,
      required: false,
      default: '#2C94E0'
    },
    data_back: {
      type: String,
      required: false,
      default: 'RGBa(0,0,0,.06)'
    },
    data_theme: {
      type: String,
      required: false,
      default: 'Red-Gold-Green'
    },
    data_animate_gauge_colors: {
      type: Boolean,
      required: false,
      default: false
    },
    data_animate_text_colors: {
      type: Boolean,
      required: false,
      default: false
    },
    data_label: {
      type: String,
      required: false,
      default: null
    },
    data_label_color: {
      type: String,
      required: false,
      default: 'Black'
    },
    data_stripe: {
      type: Number,
      required: false,
      default: 0
    },
    data_fill: {
      type: String,
      required: false,
      default: null
    },
    data_animationstep: {
      type: Number,
      required: false,
      default: 1
    },
    data_showvalue: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const { 
      data_percent, 
      data_text, 
      data_text_size, 
      data_prepend, 
      data_append, 
      data_size, 
      data_width, 
      data_style, 
      data_color, 
      data_back, 
      data_theme, 
      data_animate_gauge_colors, 
      data_animate_text_colors, 
      data_label, 
      data_label_color, 
      data_stripe, 
      data_min, 
      data_used, 
      data_total, 
      data_animationstep, 
      data_fill, 
      data_showvalue 
    } = toRefs(props);
    const number_of_charts = document.querySelectorAll('.gauge-meter').length;
    const chart_id = ref("GaugeMeter_" + number_of_charts);

    const chart_options = ref({});

    // Check if the value for each prop is there before adding it
    if (data_percent.value !== undefined) chart_options.value['data-percent'] = data_percent.value;
    if (data_text.value !== undefined) chart_options.value['data-text'] = data_text.value;
    if (data_text_size.value !== undefined) chart_options.value['data-text_size'] = data_text_size.value;
    if (data_prepend.value !== undefined) chart_options.value['data-prepend'] = data_prepend.value;
    if (data_append.value !== undefined) chart_options.value['data-append'] = data_append.value;
    if (data_size.value !== undefined) chart_options.value['data-size'] = data_size.value;
    if (data_width.value !== undefined) chart_options.value['data-width'] = data_width.value;
    if (data_style.value !== undefined) chart_options.value['data-style'] = data_style.value;
    if (data_color.value !== undefined) chart_options.value['data-color'] = data_color.value;
    if (data_back.value !== undefined) chart_options.value['data-back'] = data_back.value;
    if (data_theme.value !== undefined) chart_options.value['data-theme'] = data_theme.value;
    if (data_animate_gauge_colors.value !== undefined) chart_options.value['data-animate_gauge_colors'] = data_animate_gauge_colors.value;
    if (data_animate_text_colors.value !== undefined) chart_options.value['data-animate_text_colors'] = data_animate_text_colors.value;
    if (data_label.value !== undefined) chart_options.value['data-label'] = data_label.value;
    if (data_label_color.value !== undefined) chart_options.value['data-label_color'] = data_label_color.value;
    if (data_stripe.value !== undefined) chart_options.value['data-stripe'] = data_stripe.value;
    if (data_min.value !== undefined) chart_options.value['data-min'] = data_min.value;
    if (data_used.value !== undefined) chart_options.value['data-used'] = data_used.value;
    if (data_total.value !== undefined) chart_options.value['data-total'] = data_total.value;
    if (data_animationstep.value !== undefined) chart_options.value['data-animationstep'] = data_animationstep.value;
    if (data_fill.value !== undefined) chart_options.value['data-fill'] = data_fill.value;
    if (data_showvalue.value !== undefined) chart_options.value['data-showvalue'] = data_showvalue.value;

    const init_gauge_meter = () => {
      $(`#${chart_id.value}`).gaugeMeter();
    };

    watch(
      () => data_percent.value,
      (new_value) => {
        if (new_value !== undefined) {
          // Update the gauge meter with new value  
          $(`#${chart_id.value}`).gaugeMeter({ 
            percent: new_value 
          });
        }
      },
      { deep: true }
    );

    onMounted(() => {
      // Initialize gauge meter when component is mounted
      init_gauge_meter();
    });

    return {
      chart_id,
      chart_options
    };
  }
});
</script>

<style>
.gauge-meter {
  position: relative;
  text-align: center;
  overflow: hidden;
  cursor: default;
}

.gauge-meter span,
.gauge-meter b {
  margin: 0 23%;
  width: 54%;
  position: absolute;
  text-align: center;
  display: inline-block;
  color: rgba(0, 0, 0, .8);
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gauge-meter[data-style="Semi"] b {
  margin: 0 10%;
  width: 80%;
  font-weight: 600;
}

.gauge-meter s,
.gauge-meter u {
  text-decoration: none;
  font-size: .5em;
  font-weight: 600;
}

.gauge-meter b {
  color: black;
  font-size: .5em;
  font-weight: 600;
}
</style>
