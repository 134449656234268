import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";
import BarChart from '@/components/Graphs/BarChart.vue';
import DualAxis from '@/components/Graphs/DualAxis.vue';
import LineChart from '@/components/Graphs/LineChart.vue';
import PieChart from '@/components/Graphs/PieChart.vue';

export default {
    name: 'ClientOverview',
    components: {
        BarChart,
        DualAxis,
        LineChart,
        PieChart
    },
    mixins: [LOGOUT],
    mounted (){
        // Check if user is logged in
        if (!validateToken()) {
            this.logout();
        }

        this.get_billing_data();
    },
    data() {
        return {
            services: [],
            // Filter settings
            time_filter: 'month',
            selected_service: 'all',
            selected_service_name: null,
            
            // Time range options
            time_ranges: [
                { value: 'week', label: 'Last 7 Days' },
                { value: 'month', label: 'Last 30 Days' },
                { value: 'quarter', label: 'Last Quarter' },
                { value: 'year', label: 'Last Year' }
            ],

            // Raw data from API
            billing_data: [],
            
            // Dual axis chart config for Cost vs Service Cost
            dual_axis: {
                labels: [],
                total_data: [],
                service_data: [],
                chart_title: "Verification Costs vs Service Fees Over Time",
                xaxis_title: "Time Period",
                yaxis_title: "Total Verification Costs (R)",
                y2axis_title: "Service Fee (R)",
                bar_color: "rgba(89, 255, 116, 0.5)",
                line_color: "rgba(33, 37, 41, 1)",
                line_width: 2,
            },

            // Services Bar Chart data and layout
            chart_data: [],
            chart_layout: {
                title: { text: "Service Revenue Performance Analysis" },
                xaxis: {
                    title: { text: "Service Name" },
                    tickfont: {
                        size: 14,
                        color: "rgb(107, 107, 107)",
                    },
                },
                yaxis: {
                    title: {
                        text: "Total Revenue Generated (R)",
                        font: {
                            size: 16,
                            color: "rgb(107, 107, 107)",
                        },
                    },
                    tickfont: {
                        size: 14,
                        color: "rgb(107, 107, 107)",
                    },
                },
                legend: {
                    x: 0,
                    y: 1.0,
                    bgcolor: "rgba(255, 255, 255, 0)",
                    bordercolor: "rgba(255, 255, 255, 0)",
                },
                barmode: "group",
                bargap: 0.15,
                bargroupgap: 0.1,
            },

            // Stacked Bar Chart for Service Cost Breakdown
            stacked_chart_data: [],
            stacked_chart_layout: {
                title: { text: "Verification Service Cost Component Analysis" },
                xaxis: { title: { text: "Verification Service Type" } },
                yaxis: { title: { text: "Cost Amount (R)" } },
                barmode: "stack",
                legend: { orientation: 'h', y: 1.1 }
            },

            // Revenue Trend Graph
            revenue_trend_data: [],
            revenue_trend_layout: {
                title: { text: "Revenue Trend Over Time" },
                xaxis: { title: { text: "Date" } },
                yaxis: { title: { text: "Revenue Amount (R)" } }
            },

            // Service Usage Pie Chart
            service_usage_data: [],
            service_usage_layout: {
                title: { text: "Service Usage Distribution" },
                showlegend: true
            },

            // Revenue by Service Pie Chart
            revenue_by_service_data: [],
            revenue_by_service_layout: {
                title: { text: "Revenue Distribution by Service" },
                showlegend: true
            }
        };
    },
    computed: {
        available_services() {
            return [
                { value: 'all', label: 'All Services' },
                ...this.services.map(service => ({
                    value: service.id,
                    label: service.service_name || service.name
                }))
            ]
        },
        filtered_revenue_trend_data() {
            // Filter revenue trend based on time and service filters
            if (this.selected_service === 'all') {
                return this.revenue_trend_data;
            } else {
                // Filter by service
                const filtered = JSON.parse(JSON.stringify(this.revenue_trend_data));
                // Real filtering would happen here based on actual data
                return filtered;
            }
        },
        filtered_stacked_chart_data() {
            return this.stacked_chart_data;
        },
        filtered_chart_data() {
            if (this.selected_service === 'all') {
                return this.chart_data;
            } else {
                // Filter to show only selected service
                // Real filtering would happen here based on actual service data
                return this.chart_data;
            }
        },
        filtered_revenue_by_service_data() {
            if (this.selected_service === 'all') {
                return this.revenue_by_service_data;
            } else {
                // Filter based on selected service
                // Real filtering would happen here based on actual data
                return this.revenue_by_service_data;
            }
        },
        filtered_service_usage_data() {
            if (this.selected_service === 'all') {
                return this.service_usage_data;
            } else {
                // Filter based on selected service
                return this.service_usage_data;
            }
        }
    },
    methods: {
        /**
         * Get the billing data from the API
         */
        get_billing_data() {
            const token = localStorage.getItem('token');
            const get_service_information_api = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/get_billing_data/`;
            
            // Show loading indicator
            Swal.fire({
                title: 'Loading...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            $.ajax({
                url: get_service_information_api,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                data: {
                    time_filter: this.time_filter,
                    service_name: this.selected_service_name
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    
                    // Store raw data
                    this.billing_data = response.data || [];

                    // Extract all services
                    let all_services = response.data
                        .filter(item => item.service && (item.service.service_name || item.service.name))
                        .map(item => ({
                            id: item.service.id,
                            service_name: item.service.service_name || item.service.name,
                        }));

                    // Create a map to deduplicate services by ID
                    let services_map = new Map();
                    all_services.forEach(service => {
                        if (!services_map.has(service.id)) {
                            services_map.set(service.id, service);
                        }
                    });

                    this.services = Array.from(services_map.values());
                    
                    // Update chart data from the response
                    this.update_charts_from_response(response);
                },
                error: (error) => {
                    Swal.close();
                    console.error(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to fetch billing data'
                    });
                }
            });
        },

        /**
         * Update all chart data from API response
         */
        update_charts_from_response(response) {
            if (response.graph_data) {
                // Update Service Usage Pie Chart
                if (response.graph_data.service_usage_chart) {
                    this.service_usage_data = response.graph_data.service_usage_chart;
                }
                
                // Update Revenue by Service Pie Chart
                if (response.graph_data.revenue_by_client_chart) {
                    this.revenue_by_service_data = response.graph_data.revenue_by_client_chart;
                }
                
                // Update Revenue by Service Bar Chart
                if (response.graph_data.revenue_by_service_chart) {
                    this.chart_data = response.graph_data.revenue_by_service_chart;
                }
                
                // Update Usage Timeline Chart
                if (response.graph_data.usage_timeline_chart) {
                    this.revenue_trend_data = response.graph_data.usage_timeline_chart;
                }
                
                // Update Stacked Chart data
                if (response.graph_data.service_usage && response.graph_data.revenue_by_service) {
                    const service_names = Object.keys(response.graph_data.service_usage);
                    const base_costs = [];
                    const revenues = [];
                    
                    // Map to store base costs by service name
                    const service_cost_map = {};
                    
                    // Extract base costs from billing data
                    if (response.data && response.data.length > 0) {
                        response.data.forEach(item => {
                            if (item.service && item.service.service_name) {
                                const serviceName = item.service.service_name;
                                service_cost_map[serviceName] = parseFloat(item.base_cost) || 0;
                            }
                        });
                    }

                    // Extract base costs and revenues for each service
                    service_names.forEach(service => {
                        const revenue = response.graph_data.revenue_by_service[service] || 0;
                        const base_cost = service_cost_map[service]
                        
                        base_costs.push(base_cost);
                        revenues.push(revenue - base_cost);
                    });
                    
                    this.stacked_chart_data = [
                        {
                            x: service_names,
                            y: base_costs,
                            name: "Base Cost",
                            type: "bar",
                            marker: { color: "rgba(55, 83, 109, 0.7)" }
                        },
                        {
                            x: service_names,
                            y: revenues,
                            name: "Revenue",
                            type: "bar",
                            marker: { color: "rgba(26, 118, 255, 0.7)" }
                        }
                    ];
                }
                
                // Update Dual Axis Chart
                if (response.graph_data.usage_timeline && response.graph_data.client_cost_summary) {
                    const dates = Object.keys(response.graph_data.usage_timeline).sort();
                    const usage_counts = dates.map(date => response.graph_data.usage_timeline[date]);
                    
                    // Calculate average cost per transaction for each date
                    const cost_data = dates.map(() => {
                        // For simplicity, using an average cost across all services
                        const total_costs = Object.values(response.graph_data.client_cost_summary)
                            .reduce((sum, service) => sum + service.total_cost, 0);
                        const total_count = Object.values(response.graph_data.client_cost_summary)
                            .reduce((sum, service) => sum + service.count, 0);
                        return total_count > 0 ? total_costs / total_count : 0;
                    });
                    
                    this.dual_axis.labels = dates;
                    this.dual_axis.total_data = usage_counts;
                    this.dual_axis.service_data = cost_data;
                }
            }
        },

        /**
         * Filters data based on the selected time range
         * @param {string} range - The selected time range
         */
        filter_by_time(range) {
            this.time_filter = range;
            this.$emit('time-filter-changed', range);
            this.get_billing_data();
        },

        /**
         * Filters data based on the selected service
         * @param {string} service_id - The ID of the selected service
         */
        filter_by_service(service_id) {
            this.selected_service = service_id;
            
            // Set the service name for API filtering
            if (service_id === 'all') {
                this.selected_service_name = null;
            } else {
                const selected_service = this.services.find(service => service.id == service_id);
                this.selected_service_name = selected_service ? 
                    (selected_service.service_name || selected_service.name) : null;
            }
            
            this.$emit('service-filter-changed', service_id);
            this.get_billing_data();
        }
    }
};