export default {
  name: 'GetQuestions',
  props: {
    client_admin_id: {
      type: String,
      required: true,
    },
    field: Object,
    page_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file_input: null,
      followup_file_input: null,
      uploaded_file: null,
      responses: [],
      dynamic_fields: [],
      follow_up_questions: [],
      follow_up_responses: [],
      showLabel: true
    };
  },
  mounted() {
    this.get_questions();
    this.file_input = this.$refs.file_input;
    this.followup_file_input = this.$refs.followup_file_input;
  },
  methods: {
    show_error(question_id, is_followup = false) {
      const error_id = is_followup ? `#error-followup-${question_id}` : `#error-${question_id}`;
      $(error_id).removeClass('d-none');
      setTimeout(() => {
        $(error_id).addClass('d-none');
      }, 3000);
    },
    validate_form() {
      let is_valid = true;

      // Reset all error messages
      [...this.responses, ...this.follow_up_responses].forEach(field => {
        $(`#error-${field.question_id}`).addClass('d-none');
        $(`#error-followup-${field.question_id}`).addClass('d-none');
      });

      // Validate main form responses
      this.responses.forEach(field => {
        if (field.is_required) {
          if (field.has_multi_selection) {
            if (!field.value || (Array.isArray(field.value) && field.value.length === 0)) {
              this.show_error(field.question_id);
              is_valid = false;
            }
          } else if (field.has_file) {
            if (!field.value || field.value.type !== 'application/pdf') {
              this.show_error(field.question_id);
              is_valid = false;
            }
          } else if (!field.value || (typeof field.value === 'string' && field.value.trim() === '')) {
            this.show_error(field.question_id);
            is_valid = false;
          }
        }
      });

      // Validate follow-up responses
      this.follow_up_responses.forEach(field => {
        if (field.is_required) {
          if (field.has_multi_selection) {
            if (!field.value || (Array.isArray(field.value) && field.value.length === 0)) {
              this.show_error(field.question_id, true);
              is_valid = false;
            }
          } else if (field.has_checkbox || field.has_selection) {
            if (!field.value || field.value.trim() === '') {
              this.show_error(field.question_id, true);
              is_valid = false;
            }
          } else if (field.has_file) {
            if (!field.value || field.value.type !== 'application/pdf') {
              this.show_error(field.question_id, true);
              is_valid = false;
            }
          } else if (!field.value || (typeof field.value === 'string' && field.value.trim() === '')) {
            this.show_error(field.question_id, true);
            is_valid = false;
          }
        }
      });

      // If validation fails, scroll to first error
      if (!is_valid) {
        this.$nextTick(() => {
          const firstError = document.querySelector('.text-danger:not(.d-none)');
          if (firstError) {
            firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
      return is_valid;
    },

    on_file_change(event, question_id, is_follow_up) {
      const FILE = event.target.files[0];
      const MAX_SIZE = 5 * 1024 * 1024; // 5 MB

      if (!FILE) {
        return;
      }

      // Validate file type
      if (FILE.type !== 'application/pdf') {
        event.target.value = null;
        Swal.fire({
          text: 'Please upload a PDF file.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }

      // Check for file size
      if (FILE.size > MAX_SIZE) {
        event.target.value = null;
        Swal.fire({
          text: 'File is too large.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      this.uploaded_file = FILE;
      // Handle the file upload if the file size is acceptable
      this.handle_file_upload(event, question_id, is_follow_up);
    },

    get_follow_up_question(question_id, option_id) {

      const question = this.dynamic_fields.find(q => q.question_id === question_id);
      const option = question.options.find(opt => opt.id === option_id || opt.option === option_id);

      // Remove only the follow-up questions related to this specific question
      this.follow_up_questions = this.follow_up_questions.filter(
        q => q.parent_id !== question_id
      );

      this.follow_up_responses = this.follow_up_responses.filter(
        resp => resp.parent_id !== question_id
      );

      // Check for follow-up questions in the selected option
      if (option && option.follow_up_question && Array.isArray(option.follow_up_question)) {

        // Process all follow-up questions
        const new_follow_ups = option.follow_up_question.map(follow_up => ({
          ...follow_up,
          parent_id: question_id,
          parent_option: option_id,
          options: follow_up.options.map(opt => ({
            ...opt,
            id: opt.id || opt.option
          })),
          value: follow_up.has_checkbox ? [] : '',
          question_text: follow_up.question_text || follow_up.question,
        }));

        // Add new follow-up questions while preserving existing ones for other questions
        this.follow_up_questions = [
          ...this.follow_up_questions,
          ...new_follow_ups
        ];

        this.follow_up_responses.push(...new_follow_ups);

        // Add all follow-up questions to responses
        return new_follow_ups;
      }
    },
    update_follow_up_response(question_id, value) {
      const follow_up = this.follow_up_responses.find(resp => resp.id === question_id);
      if (follow_up) {
        follow_up.value = value;
      }
    },
    get_questions() {
      const BASE_URL = process.env.API_BASE_URL;
      let end_point = '';

      switch (this.page_type) {
        case 'register':
          end_point = 'get_register_form_api';
          break;
        case 'otp':
          end_point = 'get_otp_form_api';
          break;
        case 'customer_id_verification':
          end_point = 'get_id_verification_api';
          break;
        case 'login':
          end_point = 'get_login_form_api';
          break;
        default:
          end_point = 'get_register_form_api';
          return;
      }

      const TOKEN = localStorage.getItem('token');
      const GET_CUSTOMER_REGISTRATION_FORM_URL = `${BASE_URL}/verify_api/system_management_api/${end_point}/?client_admin_id=${this.client_admin_id}`;

      const headers = this.page_type !== 'register' ? { Authorization: `Token ${TOKEN}` } : {};
      $.ajax({
        method: "GET",
        url: GET_CUSTOMER_REGISTRATION_FORM_URL,
        contentType: 'application/json',
        headers: headers,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          if (response.questions && Array.isArray(response.questions)) {
            this.dynamic_fields = response.questions.map((question) => {

              return {
                service_id: question['service_id'],
                question_id: question['question_id'],
                question: question['question_text'],
                is_required: question['is_required'],
                question_number: question['question_number'],
                has_checkbox: question['has_checkbox'],
                has_date: question['has_date'],
                has_file: question['has_file'],
                has_number: question['has_number'],
                has_selection: question['has_selection'],
                has_multi_selection: question['has_multi_selection'],
                has_text: question['has_text'],
                options: question['options'].map(opt => ({
                  ...opt,
                  id: opt.id || opt.option
                })),
                value: question['has_checkbox'] || question['has_multi_selection'] ? [] : '',
                error_message: ''
              }
            });
            this.responses = [...this.dynamic_fields];
            this.emit_responses();
          }
        },
        error: () => {
          Swal.close();
        },
      });
    },
    handle_file_upload(event, question_id, is_follow_up) {
      const file = event.target.files[0];
      if (!file) return;

      if (is_follow_up) {
        const follow_up = this.follow_up_responses.find(resp => resp.question_id === question_id);
        if (follow_up) {
          follow_up.value = file;
          follow_up.file_name = file.name;
        }
      } else {
        const field = this.responses.find(resp => resp.question_id === question_id);
        if (field) {
          field.value = file;
          field.file_name = file.name;
        }
      }
      this.emit_responses();
    },
    emit_responses() {
      this.$emit('update-responses', this.dynamic_fields, this.follow_up_responses);
    },
  },
  watch: {
    dynamic_fields: {
      handler() {
        this.emit_responses();
      },
      deep: true,
    },
    follow_up_responses: {
      handler() {
        this.emit_responses();
      },
      deep: true,
    }
  }
};
