<div class="container-fluid">
  <div class="card rounded-3 mt-4 p-0">
    <div class="card-header bg-white border-0 mb- fw-bold">Customer Data</div>

    <div class="container mt-3">
      <ul
        class="nav nav-tabs mb-4 bg-white d-flex justify-content-between"
        id="myTab"
        role="tablist"
      >
        <li class="nav-item flex-fill me-2" role="presentation">
          <button
            class="nav-link w-100 fw-bold text-dark text-xs border-0"
            :class="{ active: currentTab === 'Profile' }"
            @click="switchTab('Profile')"
          >
            Profile
          </button>
        </li>
        <li class="nav-item flex-fill me-2" role="presentation">
          <button
            class="nav-link w-100 fw-bold text-dark text-xs border-0"
            :class="{ active: currentTab === 'AuditTrail' }"
            @click="switchTab('AuditTrail')"
          >
            Audit Trails
          </button>
        </li>
        <li class="nav-item flex-fill me-2" role="presentation">
          <!-- New Tab for Service Completed -->
          <button
            class="nav-link w-100 fw-bold text-dark text-xs border-0"
            :class="{ active: currentTab === 'ServiceCompleted' }"
            @click="switchTab('ServiceCompleted')"
          >
            Service Completed
          </button>
        </li>
        <li class="nav-item flex-fill me-2" role="presentation">
          <button
            class="nav-link w-100 fw-bold text-dark text-xs border-0"
            :class="{ active: currentTab === 'Documents' }"
            @click="switchTab('Documents')"
          >
            Documents
          </button>
        </li>
      </ul>

      <!-- Profile Tab -->
      <div class="tab-content">
        <div v-if="currentTab === 'Profile'" class="tab-pane fade show active">
          <div class="decorative-card mt-5 rounded-small">
            <div class="row">
              <p class="mb-0 text-xs fw-bold">
                Customer : {{ first_name }} {{ last_name }}
              </p>
              <span class="text-xs fw-bold"
                >Email Address:
                <span class="ms-sm-2 fw-light text-dark"
                  >{{ email }}</span
                ></span
              >
              <span class="text-xs fw-bold"
                >Date Joined:
                <span class="ms-sm-2 fw-light text-dark"
                  >{{ date_joined }}</span
                ></span
              >
            </div>
          </div>

          <p class="text-uppercase text-xs text-blue fw-bold mt-4">
            Edit User Information
          </p>
          <div class="underline rounded-small mt-1 border-2 "></div>
          <form @submit.prevent="edit_user(get_user_id)">
            <div class="row mb-3">
              <div class="col">
                <label class="form-label text-xs fw-bold">Email</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control text-xs"
                  placeholder="Email"
                />
              </div>
              <div class="col">
                <label class="form-label text-xs fw-bold">Phone number</label>
                <input
                  v-model="phone_number"
                  type="tel"
                  class="form-control text-xs"
                  placeholder="Enter phone number"
                />
              </div>
            </div>

            <div class="modal-footer border-0">
              <button
              type="submit"
              class="btn bg-green btn-sm secondary-text-dark text-xxs fw-bold rounded-small">
                Submit
              </button>
            </div>
          </form>
          <hr class="border-0">
          <p class="text-uppercase text-xs text-blue fw-bold mt-4">
            Seriti Policy
          </p>
          <div class="underline rounded-small mt-2"></div>
          <div >
            <button
              class="btn border border-2 text-white rounded-small fw-bold text-xs shadow-sm mb-3 d-flex align-items-center w-100 py-3"
              @click="policy_toggle_dropdown"
              :disabled="is_loading"  
            >
              <span class="me-2 text-dark">Policy Details</span>
              <span class="ms-auto text-dark">
                <i v-if="is_loading" class="fa fa-spinner fa-spin"></i>
                <i v-else :class="{'fa fa-chevron-down': !is_policy_visible, 'fa fa-chevron-up': is_policy_visible}"></i>
              </span>
            </button>

            <!-- Show "No data available" if processed_policy_details is empty -->
            <p v-if="!is_loading && processed_policy_details.length === 0 && is_policy_visible" class="text-muted text-center mt-2">
              No details available.
            </p>
            <div v-show="is_policy_visible && processed_policy_details.length > 0" class="dropdown-content">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <template v-for="(item, index) in processed_policy_details" :key="index">
                      <!-- Heading Row -->
                      <tr v-if="item.type === 'heading'" class="table-secondary">
                        <th class="py-2 text-s">
                          {{ format_label(item.label) }}
                        </th>
                      </tr>
                      <!-- Data Row -->
                      <tr v-if="(item.type === 'input' || item.type === 'label') && !item.label.includes('FacialImage')">
                        <td>
                          <div class="row col-12">
                            <div class="col-3">
                              <span class="fw-bold text-xs me-2">{{ format_label(item.label) }}</span>
                            </div>
                            <div class="col-9">
                              <span v-if="item.value" class="text-xs">{{ item.value }}</span>
                              <span v-else class="text-muted text-xs">None</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="policy_not_created && processed_policy_details.length === 0" class="modal-footer border-0">
            <button 
              type="submit"
              class="btn bg-green btn-sm secondary-text-dark text-xxs fw-bold rounded-small mt-2 mb-3"
              @click="send_to_seriti()">
              Send Customer Data
            </button>
          </div>
        </div>
      </div>

        <!-- Audit Trail Tab -->
        <div
          v-if="currentTab === 'AuditTrail'"
          class="tab-pane fade show active"
        >
          <div class="tab-pane fade show" id="audit_trail_tab">
            <section class="py-3">
              <div
                v-if="audit_trails.length === 0"
                class="fw-bold text-xs mb-4"
              >
                No Audit Trail for this user found.
              </div>
              <ul v-else class="timeline text-xs">
                <li
                  v-for="audit_trail in audit_trails"
                  :key="audit_trail.id"
                  class="timeline-item mb-5"
                >
                  <p class="mb-2 text-xs fw-bold text-blue">
                    {{ audit_trail.activity }}
                  </p>
                  <p class="text-muted mb-2 fw-bold">
                    {{ audit_trail.date_created }}
                  </p>
                  <label
                    v-if="audit_trail.status === 'Success'"
                    class="bg-success text-white rounded-small px-3"
                    ><i class="fa fa-check me-1" aria-hidden="true"></i
                  ></label>
                  <label
                    v-if="audit_trail.status === 'error'"
                    class="bg-danger text-white rounded-small px-3"
                  >
                    <i
                      class="fa fa-exclamation-triangle me-1"
                      aria-hidden="true"
                    ></i>
                  </label>
                </li>
              </ul>
            </section>
          </div>
        </div>

        <!-- Documents Tab -->
        <div
          v-if="currentTab === 'Documents'"
          class="tab-pane fade show active fw-bold text-xs mb-4"
        >
          <div v-if="documents.length === 0">
            This customer does not have any documents yet
          </div>
          <div v-else>
            <div v-for="document in documents" :key="document.id" class="row">
              <div class="mb-3">
                <button
                  type="button"
                  class="btn btn-dark text-white rounded-small text-xs fw-bold shadow text-uppercase"
                  @click="toggle_iframe(document)"
                >
                  {{ document.document_type }}
                </button>

                <!-- Conditionally render iframe below the button if selected -->
                <div
                  v-if="selected_document && selected_document.id === document.id"
                  class="mt-3"
                >
                  <div class="ratio ratio-1x1">
                    <iframe
                      class="w-100 border-0"
                      :src="selected_document.content"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Service Completed Tab -->
        <div class="tab-content" v-if="currentTab === 'ServiceCompleted'">
          <!-- Tabs for Credit Bureau and System Generated -->
          <ul
            class="nav nav-tabs nav-fill mb-4 d-flex justify-content-between mt-5"
            role="tablist"
          >
            <li class="nav-item me-2">
              <button
                class="custom-tab-underline btn-white bg-white border-0 text-dark text-xs fw-bold"
                :class="{ 'active-tab': current_sub_tab === 'CreditBureau' }"
                @click="switch_sub_tab('CreditBureau')"
              >
                Credit Bureau
              </button>
            </li>
            <li class="nav-item me-2">
              <button
                class="custom-tab-underline btn-white bg-white border-0 text-dark text-xs fw-bold"
                :class="{ 'active-tab': current_sub_tab === 'SystemGenerated' }"
                @click="switch_sub_tab('SystemGenerated')"
              >
                System Generated
              </button>
            </li>
          </ul>
          <!-- Credit Bureau Tab Content -->
          <div v-if="current_sub_tab === 'CreditBureau'" class="mb-3">
            <div
              v-for="service in credit_bureau_services"
              :key="service.id"
              class="service-section"
            >
              <button
                class="btn bg-dark text-white rounded-small fw-bold text-xs shadow-sm mb-2 d-flex align-items-center w-100 py-3"
                @click="toggle_service(service.service_name)"
              >
                <span class="me-2">{{ service.service_name }}</span>
                <span class="ms-auto"><i class="fa fa-chevron-down"></i></span>
              </button>

              <div
                v-if="active_credit_bureau_service === service.service_name"
                class="dropdown-content"
              >
                <div
                  v-if="service.service_name === 'Anti Money Laundering'
                    || service.service_name === 'Search FCRA'"
                >
                  <div v-if="nm_results && nm_results.length">
                    <table class="table-responsive w-100 mt-3 mb-5">
                      <thead class="text-dark">
                        <tr>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Product ID
                          </th>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Score
                          </th>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Source ID
                          </th>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Category
                          </th>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Country
                          </th>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Sentiment Confidence Level
                          </th>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Matched Subject
                          </th>
                          <th
                            class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start"
                          >
                            Highlights & URL
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="result in nm_results" :key="result.resultId">
                          <td class="text-xxs text-start">
                            {{ result.productId }}
                          </td>
                          <td class="text-xxs text-start">
                            {{ result.score }}
                          </td>
                          <td class="text-xxs text-start">
                            {{ result.sourceId }}
                          </td>
                          <td class="text-xxs text-start">
                            {{ result.category }}
                          </td>
                          <td class="text-xxs text-start">
                            {{ result.sourceCountry }}
                          </td>
                          <td class="text-xxs text-start">
                            {{ result.sentimentConfidenceLevel }}
                          </td>
                          <td class="text-xxs text-start">
                            {{ result.subjectMatched }}
                          </td>
                          <td class="text-xxs text-start">
                            {{ result.highlights[0] }}
                            <p>{{ result.url }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>
                    <div class="tab-pane fade show active fw-bold text-xs mb-4">
                      No data found.
                    </div>
                  </div>
                </div>
                <div v-if="service.service_name === 'Address Match'">
                  <!-- Data Table -->
                  <AddressComponent :data="preprocess_data(service.response)" />
                </div>
                <div v-else>
                  <div
                    v-if="service.service_name === 'ID Verification (Liveness check)'
                  || service.service_name === 'ID Verification (Facial Biometrics)'
                  || service.service_name === 'OCR verification'"
                  >
                  <div v-if="service.response.FacialImage || customer_image || service.response.document_images.Portrait">
                    <div class="w-100 text-center mb-4">
                      <label class="form-label text-s fw-bold d-block mb-1 mt-4">
                        Facial Image Comparison
                      </label>
                      <div class="d-flex justify-content-center align-items-center gap-3 mx-auto">
                        <div v-if="service.response.FacialImage" class="image-container">
                          <p class="text-dark small mb-2">Service Image</p>
                          <img
                            :src="'data:image/jpeg;base64,' + service.response.FacialImage"
                            class="facial-image img-fluid rounded shadow-sm"
                            alt="Service Facial Image"
                          />
                        </div>
                        <div v-if="customer_image" class="image-container">
                          <p class="text-dark small mb-2">Customer Image</p>
                          <img
                            :src="customer_image"
                            class="facial-image img-fluid rounded shadow-sm"
                            alt="Customer Facial Image"
                          />
                        </div>
                        <div v-if="(service.response?.document_images?.Portrait)" class="image-container">
                          <p class="text-dark small mb-2">Portrait Image</p>
                          <img
                          :src="service.response.document_images.Portrait"
                          class="facial-image img-fluid rounded shadow-sm"
                          alt="Customer Facial Image"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  <!-- Data Table -->
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <template
                          v-for="(item, index) in preprocess_data(service.response)"
                          :key="index"
                        >
                          <!-- Heading Row -->
                          <tr
                            v-if="item.type === 'heading' && format_label(item.label) !== 'Document Images'"
                            class="table-secondary"
                          >
                            <th class="py-2 text-s">
                              {{ format_label(item.label) }}
                            </th>
                          </tr>
                          <!-- Data Row -->
                           
                          <tr
                          v-if="(item.type === 'input' || item.type === 'label') && 
                          !['FacialImage', 'Portrait', 'Signature', 'Document Front Side', 'Barcode']
                            .some(excluded => item.label.toLowerCase().trim().includes(excluded.toLowerCase()))"
                          >
                            <td>
                              <div class="row col-12">
                                <div class="col-3">
                                  <span class="fw-bold text-xs me-2"
                                    >{{ format_label(item.label) }}</span
                                  >
                                </div>
                                <div class="col-9">
                                  <span v-if="item.value" class="text-xs"
                                    >{{ item.value }}</span
                                  >
                                  <span v-else class="text-muted text-xs"
                                    >None</span
                                  >
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- System Generated Tab Content -->
          <div v-if="current_sub_tab === 'SystemGenerated'" class="mb-3">
            <div
              v-for="service in system_generated_services.services"
              :key="service.id"
              class="service-section"
            >
              <button
                class="btn bg-dark text-white rounded-small fw-bold text-xs shadow-sm mb-2 d-flex align-items-center w-100 py-3"
                @click="toggle_service(service.service_name)"
              >
                <span class="me-2"
                  >{{ format_label(service.service_name) }}</span
                >
                <span class="ms-auto"> <i class="fa fa-chevron-down"></i></span>
              </button>

              <!-- Service Dropdown Content -->
              <div
                v-if="active_system_generated_service === service.service_name"
              >
                <div
                  class="row"
                  :class="{'g-4': Object.keys(service.questions).length > 3}"
                >
                  <div
                    v-for="question in service.questions"
                    :key="question"
                    class="col-md-6 mt-4 mb-2"
                  >
                    <label class="form-label text-xs fw-bold"
                      >{{ question.question }}</label
                    >
                    <div
                      v-if="question.response.startsWith('https://verifybucket.s3')"
                    >
                      <!-- Render as a button if it's a document link -->
                      <button
                        type="button"
                        class="btn btn-dark text-white rounded-small text-xs shadow text-uppercase"
                        @click="show_document(question.response)"
                      >
                        View Document
                      </button>
                      <!-- Conditionally render iframe below the button if the document URL is selected -->
                      <div
                        v-if="selected_document_url === question.response"
                        class="mt-3"
                      >
                        <iframe
                          :src="question.response"
                          class="w-100 border-0 iframe-style"
                        ></iframe>
                      </div>
                    </div>
                    <div
                      v-if="Array.isArray(format_questions(question.response))"
                      v-for="(item, index) in format_questions(question.response)"
                      :key="index"
                      class="mb-2"
                    >
                      <input
                        type="text"
                        class="form-control text-xs rounded-small p-3 shadow-sm"
                        :value="item"
                        disabled
                      />
                    </div>
                    <div v-else>
                      <!-- Render as a text input if it's not a document link -->
                      <input
                        type="text"
                        class="form-control text-xs rounded-small p-3 shadow-sm"
                        :value="question.response"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>