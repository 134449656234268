<div class="container">
    <!-- Modern Tab Navigation -->
    <div class="card shadow-md rounded-3 mb-4">
        <div class="card-body">
            <ul class="nav nav-tabs justify-content-center border-0">
                <li class="nav-item">
                    <a class="nav-link text-dark text-xs fw-light"
                        :class="{ 'active': active_tab === 'client_data' }"
                        @click.prevent="switch_tab('client_data')" href="#">
                        <i class="fas fa-table me-2"></i>Client Data
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-dark text-xs fw-light" :class="{ 'active': active_tab === 'overview' }"
                        @click.prevent="switch_tab('overview')" href="#">
                        <i class="fas fa-chart-bar me-2"></i>Overview
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <!-- Client Data Tab with Table -->
    <div v-if="active_tab === 'client_data'">
        <transition name="fade" mode="out-in">
            <!-- Client List Table (default view) -->
            <div v-if="!selected_client_detail" key="client-list"
                class="card card-outline-primary mt-2 rounded-3 shadow">
                <div class="card-body p-0 px-4 py-3">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <label class="fw-bold text-center mb-0">
                            <i class="fas fa-users text-primary fa-xl py-2 me-2"></i>
                            Client Information
                        </label>
                        <!-- New search input for client data table -->
                        <input type="text" class="form-control w-25" v-model="search_query"
                            placeholder="Search Clients...">
                    </div>
                    <!-- Responsive table container with updated table classes -->
                    <div class="table-responsive">
                        <table class="table table-striped table-hover table-sm text-xs">
                            <thead>
                                <tr>
                                    <th class="text-xs">No</th>
                                    <th class="text-xs">Client Name</th>
                                    <th class="text-xs">Cost Created (R)</th>
                                    <th class="text-xs">Revenue Generated (R)</th>
                                    <th class="text-xs">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="client in filtered_clients" :key="client.id">
                                    <td class="py-2">{{ client.no }}</td>
                                    <td class="py-2">{{ client.name }}</td>
                                    <td class="py-2">{{ client.total_cost }}</td>
                                    <td class="py-2">{{ client.revenue }}</td>
                                    <td class="py-2">
                                        <button class="btn btn-bg-green rounded-small text-xs"
                                            @click="show_client_detail(client)">
                                            <i class="fas fa-info-circle"></i> Services
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Paginator styling similar to Log Table -->
                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_page"
                            :disabled="current_page === 1">
                            <i class="fas fa-arrow-left"></i>
                        </button>
                        <span class="text-xs my-2">Page {{ current_page }} of {{ total_pages }}</span>
                        <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_page"
                            :disabled="current_page === total_pages">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Client Detail View (services) -->
            <div v-else-if="selected_client_detail && !selected_service" key="client-detail"
                class="card card-outline-primary mt-2 rounded-3 shadow">
                <div class="card-body p-0 px-4 py-3">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <div class="d-flex align-items-center">
                            <button class="btn btn-sm btn-transparent me-2" @click="close_client_detail">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <label class="text-center mb-0">
                                <i class="fas fa-user text-primary fa-xl py-2 me-2"></i>
                                <span class="fw-bold">{{ selected_client_detail.name }}</span>
                                -
                                <span class="text-muted fst-italic text-xs">
                                    Services
                                </span>
                            </label>
                        </div>
                        <div class="badge text-dark p-2">
                            Total Revenue:
                            <span
                                :class="`me-1 ${selected_client_detail.revenue > 0 ? 'text-success' : 'text-danger'}`">
                                R {{ selected_client_detail.revenue }}
                            </span>
                        </div>
                    </div>

                    <!-- Client Service Summary -->
                    <div class="row mb-4">
                        <div class="col-md-3 mb-3">
                            <div class="card shadow-md rounded-3">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex align-items-center p-3">
                                            <i class="fas fa-server fa-2x text-primary"></i>
                                        </div>
                                        <div class="text-end">
                                            <h4 class="mb-0">{{ this.client_services.length || 0 }}</h4>
                                            <span class="text-xs">Total Services</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="card shadow-md rounded-3">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex align-items-center p-3">
                                            <i class="fas fa-check-circle fa-2x text-primary"></i>
                                        </div>
                                        <div class="text-end">
                                            <h4 class="mb-0">{{ this.client_services.filter(s => s.status === 'Active').length || 0 }}</h4>
                                            <span class="text-xs">Active Services</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="card shadow-md rounded-3">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex align-items-center p-3">
                                            <i class="fas fa-coins fa-2x text-primary"></i>
                                        </div>
                                        <div class="text-end">
                                            <h4 class="mb-0">R{{ parseFloat(this.calculate_average_cost()) || 0 }}</h4>
                                            <span class="text-xs">Average Cost</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="card shadow-md rounded-3">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex align-items-center p-3">
                                            <i class="fas fa-percentage fa-2x text-primary"></i>
                                        </div>
                                        <div class="text-end">
                                            <h4 class="mb-0">{{ parseFloat(this.calculate_profit_margin()) || 0 }}%</h4>
                                            <span class="text-xs">Profit Margin</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Client Services Table -->
                    <div class="table-responsive">
                        <table class="table table-striped table-hover table-sm text-xs">
                            <thead>
                                <tr>
                                    <th class="text-xs">Service Name</th>
                                    <th class="text-xs">Usage Count</th>
                                    <th class="text-xs">Cost (R)</th>
                                    <th class="text-xs">Revenue (R)</th>
                                    <th class="text-xs">Price per Run (R)</th>
                                    <th class="text-xs">Status</th>
                                    <th class="text-xs">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(service, index) in paginated_client_services" :key="index">
                                    <td class="py-2">{{ service.name }}</td>
                                    <td class="py-2">{{ service.usage }}</td>
                                    <td class="py-2">{{ Number(service.cost).toFixed(2) }}</td>
                                    <td class="py-2">{{ Number(service.revenue).toFixed(2) }}</td>
                                    <td class="py-2">{{ Number(service.price_per_run).toFixed(2) }}</td>
                                    <td class="py-2">
                                        <span
                                            :class="`badge p-2 ${service.status === 'Active' ? 'bg-success' : 'bg-secondary'}`">
                                            {{ service.status }}
                                        </span>
                                    </td>
                                    <td class="py-2">
                                        <button class="btn btn-bg-info rounded-small text-xs me-1"
                                            @click="edit_service(service, index)">
                                            <i class="fas fa-edit"></i>
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Service Table Paginator -->
                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_service_page"
                            :disabled="service_current_page === 1">
                            <i class="fas fa-arrow-left"></i>
                        </button>
                        <span class="text-xs my-2">Page {{ service_current_page }} of {{ service_total_pages
                            }}</span>
                        <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_service_page"
                            :disabled="service_current_page === service_total_pages">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>


                </div>
            </div>

            <!-- Service Detail View (edit service and thresholds) -->
            <div v-else key="service-detail" class="card card-outline-primary mt-2 rounded-3 shadow">
                <div class="card-body p-0 px-4 py-3">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <div class="d-flex align-items-center">
                            <button class="btn btn-sm btn-transparent me-2" @click="close_service_detail">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <label class="text-center mb-0">
                                <i class="fas fa-cogs text-primary fa-xl py-2 me-2"></i>
                                <span class="fw-bold">
                                    {{ selected_service.name }}
                                </span>
                                -
                                <span class="text-muted fst-italic text-xs">
                                    Edit Service
                                </span>
                            </label>
                        </div>
                        <div>
                            <button class="btn btn-bg-green rounded-small text-xs" @click="save_service_changes">
                                <i class="fas fa-save me-1"></i> Save Changes
                            </button>
                        </div>
                    </div>

                    <!-- Service Edit Form -->
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="card rounded-3 mb-3">
                                <div class="card-header bg-white">
                                    <h6 class="text-sm pt-2">Basic Information</h6>
                                </div>
                                <div class="card-body">
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Service Name</label>
                                        <input type="text" class="form-control form-control-sm"
                                            v-model="edited_service.name" readonly>
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Base Cost per Run (R)</label>
                                        <input type="number" class="form-control form-control-sm"
                                            v-model.number="edited_service.base_cost" step="0.01" min="0" readonly>
                                        <small class="text-xxs text-muted">The actual cost to provide this
                                            service</small>
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Client Price per Run (R)</label>
                                        <input type="number" class="form-control form-control-sm"
                                            v-model.number="edited_service.price_per_run" step="0.01" min="0">
                                        <small class="text-xxs text-muted">The price charged to the client</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card rounded-3">
                                <div class="card-header bg-white d-flex justify-content-between align-items-center">
                                    <h6 class="text-sm pt-2">Revenue & Usage</h6>
                                </div>
                                <div class="card-body">
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Current Usage</label>
                                        <p class="form-control-plaintext text-sm">{{ edited_service.usage }}</p>
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Total Base Cost (R)</label>
                                        <p class="form-control-plaintext text-sm">R {{ calculate_base_cost_total()
                                            }}</p>
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Total Client Revenue (R)</label>
                                        <p class="form-control-plaintext text-sm">R {{
                                            calculate_client_revenue_total() }}</p>
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Net Revenue (R)</label>
                                        <p class="form-control-plaintext text-sm">R {{ calculate_net_revenue() }}
                                        </p>
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Profit Margin</label>
                                        <div class="d-flex align-items-center">
                                            <p class="form-control-plaintext text-sm mb-0">{{ calculate_service_profit_margin() }} %</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Volume Thresholds -->
                    <div class="card rounded-3 mb-3">
                        <div class="card-header bg-white d-flex justify-content-between align-items-center">
                            <h6 class="text-sm pt-2">Volume Pricing Thresholds</h6>
                            <div class="d-flex">
                                <button class="btn btn-bg-info rounded-small text-xs mx-1" @click="add_threshold">
                                    <i class="fas fa-plus me-1"></i> Add Threshold
                                </button>
                                <button class="btn btn-bg-green rounded-small text-xs" @click="save_threshold">
                                    <i class="fas fa-plus me-1"></i> Save Threshold
                                </button>
                            </div>
                        </div>
                        <transition-group name="list" tag="div" class="card-body p-0">
                            <div v-for="(threshold, index) in paginated_volume_thresholds" :key="threshold.id"
                                class="threshold-item border-bottom p-3">
                                <div class="row align-items-center">
                                    <div class="col-md-2">
                                        <label class="form-label text-xs mb-1">Volume Threshold</label>
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text icon-transparent">≥</span>
                                            <input type="number" class="form-control"
                                                v-model.number="threshold.threshold" min="1">
                                            <span class="input-group-text icon-transparent">runs</span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label class="form-label text-xs mb-1">Type</label>
                                        <select class="form-select form-select-sm" v-model="threshold.type">
                                            <option value="absolute">Absolute</option>
                                            <option value="percentage">Percentage</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="form-label text-xs mb-1">
                                            <span v-if="threshold.type === 'absolute'">Client Price per Run
                                                (R)</span>
                                            <span v-else>Discount Percentage (%)</span>
                                        </label>
                                        <input type="number" class="form-control form-control-sm"
                                            v-if="threshold.type === 'absolute'" v-model.number="threshold.discount"
                                            min="0" step="0.01">
                                        <div class="input-group input-group-sm" v-else>
                                            <input type="number" class="form-control"
                                                v-model.number="threshold.discount" min="0" max="100" step="0.01">
                                            <span class="input-group-text icon-transparent">%</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-if="threshold.type === 'absolute'">
                                        <label class="form-label text-xs mb-1">Calculated Discount</label>
                                        <div class="input-group input-group-sm">
                                            <input type="text" class="form-control"
                                                :value="calculate_discount(threshold) + '%'" readonly>
                                            <span class="input-group-text icon-transparent"
                                                :class="get_discount_color_class(threshold)">
                                                <i class="fas fa-percentage"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label class="form-label text-xs mb-1 d-block">&nbsp;</label>
                                        <button class="btn btn-sm rounded-3 shadow btn-outline-danger"
                                            @click="remove_threshold(get_actual_threshold_index(index))">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                        <div v-if="edited_service.volume_thresholds.length === 0"
                            class="card-body text-center text-muted py-4">
                            <p class="mb-0 text-xs">No volume thresholds configured. Add one to enable tiered
                                pricing.</p>
                        </div>

                        <!-- Volume Thresholds Table Paginator -->
                        <div class="d-flex justify-content-between align-items-center mt-2 p-3"
                            v-if="edited_service.volume_thresholds.length > threshold_items_per_page">
                            <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_threshold_page"
                                :disabled="threshold_current_page === 1">
                                <i class="fas fa-arrow-left"></i>
                            </button>
                            <span class="text-xs my-2">Page {{ threshold_current_page }} of {{ threshold_total_pages
                                }}</span>
                            <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_threshold_page"
                                :disabled="threshold_current_page === threshold_total_pages">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>

                    <!-- Preview Price Structure with Pagination -->
                    <div class="card rounded-3 mb-3">
                        <div class="card-header bg-white">
                            <h6 class="text-sm pt-2">Price Structure Preview</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-sm text-xs">
                                    <thead>
                                        <tr>
                                            <th>Usage Level</th>
                                            <th>Price per Run</th>
                                            <th>Discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="get_sorted_thresholds().length === 0">
                                            <td>1 - ∞ runs</td>
                                            <td>R{{ edited_service ? edited_service.price_per_run : 0 }}</td>
                                            <td>0%</td>
                                        </tr>
                                        <tr v-else>
                                            <td>1 - {{ get_first_threshold_volume() - 1 || '∞' }} runs</td>
                                            <td>R{{ edited_service ? edited_service.price_per_run : 0 }}</td>
                                            <td>0%</td>
                                        </tr>
                                        <tr v-for="(threshold, index) in paginated_sorted_thresholds"
                                            :key="threshold.id">
                                            <td>
                                                {{ threshold.threshold }} -
                                                {{ get_next_threshold_volume(get_actual_preview_index(index)) !== -1 ? get_next_threshold_volume(get_actual_preview_index(index)) - 1 : '∞' }} runs
                                            </td>
                                            <td
                                                v-if="threshold.type == 'absolute'"
                                            >
                                                R{{ threshold.discount }}
                                            </td>
                                            <td
                                                v-if="threshold.type == 'percentage'"
                                            >
                                                R {{ Number(edited_service.price_per_client || edited_service.base_cost * (calculate_discount(threshold) / 100)).toFixed(2) }}
                                            </td>
                                            <td>{{ calculate_discount(threshold) }}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Preview Table Paginator -->
                            <div class="d-flex justify-content-between align-items-center mt-2"
                                v-if="edited_service && get_sorted_thresholds().length > preview_items_per_page">
                                <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_preview_page"
                                    :disabled="preview_current_page === 1">
                                    <i class="fas fa-arrow-left"></i>
                                </button>
                                <span class="text-xs my-2">Page {{ preview_current_page }} of {{ preview_total_pages
                                    }}</span>
                                <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_preview_page"
                                    :disabled="preview_current_page === preview_total_pages">
                                    <i class="fas fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- Overview Tab with Charts -->
    <div v-if="active_tab === 'overview'">
        <ClientOverview />
    </div>
</div>