<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-4">
      <div class="card card-bg shadow-sm rounded-3 border-0 p">
        <div class="card-body">
          <div class="d-flex justify-content-between px-md-1">
            <div>
              <h6>CUSTOMERS</h6>
              <p class="mb-0 sub_heading text-xsm fw-bold">
                {{ total_project_customers }}
              </p>
            </div>
            <div class="align-self-center rounded-circle p-2 shadow bg-green">
              <i
                class="fa fa-users fa-lg me-auto secondary-text-dark white-icon"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-bg shadow-sm rounded-3 border-0">
        <div class="card-body">
          <div class="d-flex justify-content-between px-md-1">
            <div>
              <h6>ACTIVE WORKFLOWS</h6>
              <p class="mb-0 sub_heading text-xsm fw-bold">
                {{ active_workflows }}
              </p>
            </div>
            <div class="align-self-center rounded-circle p-2 shadow bg-green">
              <i class="fas fa-cogs fa-lg secondary-text-dark white-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-bg shadow-sm rounded-3 border-0 mb-2">
        <div class="card-body">
          <div class="d-flex justify-content-between px-md-1">
            <div>
              <h6>ADMINS</h6>
              <p class="mb-0 sub_heading text-xsm fw-bold">{{ admins }}</p>
            </div>
            <div class="align-self-center rounded-circle p-2 shadow bg-green">
              <i
                class="fas fa-user-shield fa-lg secondary-text-dark white-icon"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="loading" class="text-center mt-5">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-else>
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card rounded-3 shadow p-0 mt-4">
          <div class="card-header bg-white border-0">
            Your Workflows
            <button
              type="button"
              class="btn btn-dark rounded-small fw-bold shadow btn-sm float-end text-xxs"
              data-bs-toggle="modal"
              data-bs-target="#create_workflow"
            >
              <i class="fas fa-plus"></i> New Workflow
            </button>
          </div>
          <div class="card-body">
            <table id="workflow-table" class="table-responsive w-100">
              <thead class="bg-dark text-white">
                <tr>
                  <th
                    class="bg-dark text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                  >
                    ID
                  </th>
                  <th
                    class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7"
                  >
                    Workflow Name
                  </th>
                  <th
                    class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7"
                  >
                    Date Created
                  </th>
                  <th
                    class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7"
                  >
                    Workflow Status
                  </th>
                  <th
                    class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7"
                  >
                    Control Panel
                  </th>
                  <th
                    class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(workflow, index) in workflows"
                  :key="workflow.workflow_id"
                >
                  <td class="text-center text-xxs">{{ index + 1 }}</td>
                  <td class="text-xxs">{{ workflow.workflow_name }}</td>
                  <td class="text-xxs">
                    {{ format_date(workflow.date_created) }}
                  </td>
                  <td>
                    <span
                      class="text-xxs"
                      :class="['badge border text-xxs', get_status(workflow.workflow_status)]"
                    >
                      {{ workflow.workflow_status }}
                    </span>
                  </td>
                  <td>
                    <div
                      v-if="role == 'ClientAdmin'"
                      class="form-check form-switch"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="workflow.is_active"
                        @change="toggle_workflow_status(workflow.workflow_id, $event.target.checked)"
                        :disabled="workflow.workflow_status == 'pending' || workflow.workflow_status == 'decline' || workflow.workflow_status == 'incomplete'"
                      />
                      <label
                        class="form-check-label text-xxs"
                        :for="'workflow-switch' + workflow.workflow_id"
                      >
                        {{ workflow.is_active ? 'Active' : 'Inactive' }}
                      </label>
                    </div>
                  </td>
                  <td>
                    <button
                      class="btn bg-green rounded-small secondary-text-dark btn-sm me-2 fw-bold text-xxs shadow text-white"
                      @click="view_workflow(workflow.workflow_id)"
                    >
                      View Customers
                    </button>
                    <button
                      class="btn btn-sm btn-dark rounded-small fw-bold btn-sm me-2 text-xxs shadow"
                      @click="edit_workflow(workflow.workflow_id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Create Workflow Modal -->
  <div
    class="modal fade"
    id="create_workflow"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="create_workflow_label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-white rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title text-s fw-bold" id="create_workflow_label">
            Create Workflow
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <input
              type="text"
              v-model="workflow_name"
              class="form-control text-xs rounded-small p-3 shadow-sm"
              placeholder="Workflow Name"
            />
            <small
              class="text-danger p-3 text-xxs fw-bold d-none workflow-name-messages"
              >Please enter a workflow name</small
            >
            <small
              class="text-danger p-2 text-xxs fw-bold d-none error-messages"
            ></small>
          </div>
          <div class="form-group mb-3">
            <textarea
              v-model="workflow_description"
              class="form-control h-100 text-xs rounded-small shadow-sm"
              placeholder="Workflow Description"
              rows="5"
            ></textarea>
            <small
              class="text-danger p-2 text-xxs fw-bold d-none workflow-description-messages"
              >Please enter a workflow description</small
            >
          </div>
          <button
            type="button"
            class="btn bg-green float-end shadow text-xs fw-bold"
            @click="create_workflow()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
