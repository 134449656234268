import Swal from 'sweetalert2';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";


export default {
  name: 'ThankYouPage',
  mixins: [LOGOUT, MAIN_CONTROLLER],
  data() {
    return {
      workflows: []
    };
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.check_sub_workflows();
  },
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  methods: {
    client_application_sub_workflow(workflow) {
      if (workflow.sub_workflow_type == 'internal') {
        const token = localStorage.getItem('token');
        const base_url = process.env.API_BASE_URL;

        const get_customer_application_url = `${base_url}/verify_api/service_management_api/create_sub_workflow_application/`;
        $.ajax({
          method: 'POST',
          url: get_customer_application_url,
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${token}`
          },
          data: JSON.stringify({ workflow_id: workflow.workflow_id }),
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            })
          },
          success: (response) => {
            Swal.close();
            if (response.status === 'success') {
              this.$store.dispatch('update_workflow_id', workflow.workflow_id);
              this.fetch_current_service();
            }
          },
          error: (xhr) => {
            Swal.close();

            const error_msg = xhr.responseJSON?.message || 'Failed to continue with application.';
            Swal.fire({
              icon: 'error',
              title: 'System Error',
              text: error_msg,
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false
            });
          }
        });
      }
      else {
        window.open(workflow.external_url, '_blank');
      }
    },
    check_sub_workflows() {
      const token = localStorage.getItem('token');
      const base_url = process.env.API_BASE_URL;

      const get_customer_application_url = `${base_url}/verify_api/service_management_api/check_sub_workflows/?workflow_id=${this.workflow_id}`;
      $.ajax({
        method: 'GET',
        url: get_customer_application_url,
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${token}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          })
        },
        success: (response) => {
          Swal.close();
          if (response.status === 'success') {
            var sub_workflows = response.data.sub_workflows;
            var sub_external_workflows = response.data.sub_external_workflows;

            // Combine internal and external workflows
            var combined_workflows = [];

            // Process internal sub-workflows
            if (Array.isArray(sub_workflows)) {
              combined_workflows = combined_workflows.concat(
                sub_workflows.filter(
                  workflow => workflow.is_active === true
                ).map(workflow => ({
                  workflow_id: workflow.workflow_id,
                  workflow_name: workflow.workflow.workflow_name,
                  service_name: workflow.service.service_name,
                  external_link: '',
                  sub_workflow_type: 'internal' // Explicitly mark as internal
                }))
              );
            }

            // Process external sub-workflows
            if (Array.isArray(sub_external_workflows)) {
              combined_workflows = combined_workflows.concat(
                sub_external_workflows.filter(
                  workflow => workflow.is_active === true
                ).map(workflow => ({
                  workflow_id: '',
                  workflow_name: '',
                  service_name: workflow.service.service_name,
                  external_url: workflow.external_link,
                  sub_workflow_type: 'external' // Explicitly mark as external
                }))
              );
            }

            this.workflows = combined_workflows;
          } else {
            this.workflows = [];
          }
        },
        error: (xhr) => {
          Swal.close();

          const error_msg = xhr.responseJSON?.message || 'Failed to retrieve workflow information';
          Swal.fire({
            icon: 'error',
            title: 'Retrieval Failed',
            text: error_msg,
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
          });
        }
      });
    },
    redirect_to_dashboard() {
      this.logout();
    }
  },
};
